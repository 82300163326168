import {Dropdown, Grid} from "semantic-ui-react";
import React from "react";

export const ClientsRow = ({companies, onChange}) => (
  <Grid.Row columns={2} style={{paddingTop: 'unset', paddingBottom: 'unset'}}>
    <Grid.Column>
      <div className={'label'}>
        {'Cliente'}
      </div>
      <CompaniesDropdown
        name={'clientCompany'}
        placeholder={'Cliente'}
        onChange={onChange}
        companies={companies}
      />
    </Grid.Column>
    <Grid.Column>
      <div className={'label'}>
        {'Empresa final'}
      </div>
      <CompaniesDropdown
        name={'finalCompany'}
        placeholder={'Empresa final'}
        onChange={onChange}
        companies={companies}
      />
    </Grid.Column>
  </Grid.Row>
);

const CompaniesDropdown = ({name, placeholder, companies, onChange}) => (
  <Dropdown
    basic selection scrolling lazyLoad fluid
    name={name}
    onChange={onChange}
    options=
      {
        companies
          ? companies.map(company => ({
            key: company.id,
            value: company.id,
            text: company.name,
          }))
          : []
      }
    placeholder={placeholder}
    noResultsMessage={'No se encontraron resultados.'}
  />
);
