import React from 'react';
import {Table} from "semantic-ui-react";
import {connect} from "react-redux";
import {openClientCompanyDeleteModalAndSetCurrentId} from "../actions";
import DeleteButton from "../../../components/table-buttons/delete-button";
import DetailButton from "../../../components/table-buttons/details-button";
import CreateClientCompanyModal from "./create-client-company-modal";
import DeleteClientCompanyModal from "./delete-client-company-modal";

export class ClientCompaniesTable extends React.Component {
  headers = [
    'Acciones',
    'Nombre',
    'Email',
    'Teléfono',
    'Dirección',
  ];

  render() {
    return (
      <Table compact={'very'} striped>
        <Table.Header>
          <Table.Row>
            {
              this.headers.map((header) =>
                <Table.HeaderCell key={header} textAlign='center'>
                  {header}
                </Table.HeaderCell>)
            }
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {
            this.props.clientCompanies.map((clientCompany) => {
              return (
                <Table.Row key={clientCompany.id}>
                  <Table.Cell collapsing textAlign={'center'}>
                    <DetailButton onClick={() => null}/>
                    <DeleteButton
                      onClick={() => this.props.openClientCompanyDeleteModalAndSetCurrentId(clientCompany.id)}/>
                  </Table.Cell>
                  <Table.Cell collapsing textAlign={'center'}>{clientCompany.name}</Table.Cell>
                  <Table.Cell collapsing textAlign={'center'}>{clientCompany.email}</Table.Cell>
                  <Table.Cell collapsing textAlign={'center'}>{clientCompany.phoneNumber}</Table.Cell>
                  <Table.Cell collapsing textAlign={'center'}>{clientCompany.address}</Table.Cell>
                </Table.Row>
              );
            })
          }
        </Table.Body>
        <CreateClientCompanyModal/>
        <DeleteClientCompanyModal/>
      </Table>
    );
  }
}

const mapDispatchToProps = {
  openClientCompanyDeleteModalAndSetCurrentId
};

export default connect(null, mapDispatchToProps)(ClientCompaniesTable);
