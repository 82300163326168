import React from 'react';
import {Button, Container, Grid, Header, List} from "semantic-ui-react";
import JobOpeningCandidatesTable from './job-opening-candidates-table'
import {connect} from "react-redux";
import {openJobOpeningCandidateAddModal} from "../actions";

class JobOpeningDetails extends React.Component {
  render() {
    const {jobOpening} = this.props;
    return (
      <Container>
        <Grid columns={16} style={{
          padding: '1em',
          marginTop: '1em',
          border: '1px solid #d4d4d5',
          borderRadius: '1px',
          background: '#fafafa',
        }}>
          <Grid.Column width={13}>
            <Grid columns={12}>
              <TitleRow jobOpening={jobOpening}/>
              <MainDetails jobOpening={jobOpening}/>
              <TechnologiesRow jobOpening={jobOpening}/>
              <LanguagesRow jobOpening={jobOpening}/>
              <DescriptionRow jobOpening={jobOpening}/>
              <CandidatesRow jobOpening={jobOpening}/>
            </Grid>
          </Grid.Column>
          <Grid.Column width={3}>
            <ProfileActionButtons addCandidate={() => this.props.openJobOpeningCandidateAddModal()}/>
          </Grid.Column>
        </Grid>
      </Container>
    )
  }
}

const ProfileActionButtons = ({addCandidate}) => (
  <Grid>
    <Grid.Row style={{paddingBottom: 0}}>
      <Grid.Column>
        <Button
          content={'Editar'} fluid icon={'edit'}
          onClick={() => alert('Editar clicked')}
        />
      </Grid.Column>
    </Grid.Row>
    <Grid.Row style={{paddingBottom: 0}}>
      <Grid.Column>
        <Button
          content={'Subir archivo'} fluid icon={'upload'}
          onClick={() => alert('Subir clicked')}
        />
      </Grid.Column>
    </Grid.Row>
    <Grid.Row style={{paddingBottom: 0}}>
      <Grid.Column>
        <Button
          positive
          content={'Añadir candidatos'} fluid icon={'add'}
          onClick={addCandidate}
        />
      </Grid.Column>
    </Grid.Row>
  </Grid>
);

const TitleRow = ({jobOpening: {name}}) => (
  <Grid.Row>
    <Grid.Column width={16}>
      <Header textAlign='center' size={'large'}>
        <Header.Content content={name}/>
      </Header>
    </Grid.Column>
  </Grid.Row>
);

const MainDetails = ({jobOpening: {priority, status, minSalary, maxSalary, clientCompany, finalCompany}}) => (
  <>
    <Grid.Row style={{paddingBottom: 0}}>
      <Grid.Column width={16}>
        <Header content={'Información general'}/>
      </Grid.Column>
    </Grid.Row>
    <Grid.Row>
      <Grid.Column width={16}>
        <b>{'Cliente: '}</b>
        {clientCompany}
      </Grid.Column>
      <Grid.Column width={16}>
        <b>{'Empresa final: '}</b>
        {finalCompany}
      </Grid.Column>
      <Grid.Column width={16}>
        <b>{'Estado: '}</b>
        {status}
      </Grid.Column>
      <Grid.Column width={16}>
        <b>{'Prioridad: '}</b>
        {priority}
      </Grid.Column>
      <Grid.Column width={16}>
        <b>{'Salario mínimo: '}</b>
        {minSalary}
      </Grid.Column>
      <Grid.Column width={16}>
        <b>{'Salario máximo: '}</b>
        {maxSalary}
      </Grid.Column>
    </Grid.Row>
  </>
);

const DescriptionRow = ({jobOpening: {description}}) => (
  <Grid.Row>
    <Grid.Column width={16}>
      <Header content={'Notas generales'}/>
      <p>{description}</p>
    </Grid.Column>
  </Grid.Row>
);

const CandidatesRow = ({jobOpening: {id, appliedCandidates}}) => (
  <Grid.Row>
    <Grid.Column width={16}>
      <Header content={'Candidatos aplicados'}/>
      <JobOpeningCandidatesTable jobOpeningId={id} appliedCandidates={appliedCandidates}/>
    </Grid.Column>
  </Grid.Row>
);

const LanguagesRow = ({jobOpening: {languagesRequired = []}}) => (
  <>
    <Grid.Row style={{paddingBottom: 0}}>
      <Grid.Column width={16}>
        <Header content={'Idiomas requeridos'}/>
      </Grid.Column>
    </Grid.Row>
    <Grid.Row>
      {
        languagesRequired.map(languages => (
          <Grid.Column key={languages} width={4}>
            <List bulleted>
              <List.Item>{languages}</List.Item>
            </List>
          </Grid.Column>
        ))
      }
    </Grid.Row>
  </>
);

const TechnologiesRow = ({jobOpening: {technologiesRequired = []}}) => (
  <>
    <Grid.Row style={{paddingBottom: 0}}>
      <Grid.Column width={16}>
        <Header content={'Tecnologías requeridas'}/>
      </Grid.Column>
    </Grid.Row>
    <Grid.Row>
      {
        technologiesRequired.map(technology => (
          <Grid.Column key={technology} width={2}>
            <List bulleted>
              <List.Item>{technology}</List.Item>
            </List>
          </Grid.Column>
        ))
      }
    </Grid.Row>
  </>
);

const mapDispatchToProps = {
  openJobOpeningCandidateAddModal,
};

export default connect(null, mapDispatchToProps)(JobOpeningDetails);
