import {
  CANDIDATE_DETAIL_EDIT,
  CANDIDATE_DETAIL_EDIT_FAILURE,
  CANDIDATE_DETAIL_EDIT_SUCCESS,
  CANDIDATE_DETAIL_FETCH,
  CANDIDATE_DETAIL_FETCH_FAILURE,
  CANDIDATE_DETAIL_FETCH_RESET_STATUS,
  CANDIDATE_DETAIL_FETCH_SUCCESS,
  CLOSE_CANDIDATE_DETAIL_EDIT_MODAL,
  SHOW_CANDIDATE_DETAIL_EDIT_MODAL
} from "./action-types";
import agent from "../../api_client/api-client";

const fetchCandidate = () => ({
  type: CANDIDATE_DETAIL_FETCH
});

const fetchCandidateSuccess = candidate => ({
  type: CANDIDATE_DETAIL_FETCH_SUCCESS,
  payload: {
    candidate: candidate
  }
});

const fetchCandidateFailure = () => ({
  type: CANDIDATE_DETAIL_FETCH_FAILURE
});

const resetCandidateFetchStatus = () => ({
  type: CANDIDATE_DETAIL_FETCH_RESET_STATUS
});

const editCandidate = _ => ({
  type: CANDIDATE_DETAIL_EDIT
});

const editCandidateSuccess = response => ({
  type: CANDIDATE_DETAIL_EDIT_SUCCESS,
  payload: {
    candidate: response
  }
});

const editCandidateFailure = error => ({
  type: CANDIDATE_DETAIL_EDIT_FAILURE,
  error: error.toString()
});

export const fetchCandidateDetails = candidateId => dispatch => {
  dispatch(resetCandidateFetchStatus());
  dispatch(fetchCandidate());
  return agent.Candidates.detail(candidateId)
    .then(response => dispatch(fetchCandidateSuccess(response)))
    .catch(error => dispatch(fetchCandidateFailure(error)));
};

export const updateCandidateDetails = (candidateId, candidateProps) => dispatch => {
  dispatch(editCandidate());
  return agent.Candidates.update(candidateId, candidateProps)
    .then(response => dispatch(editCandidateSuccess(response)))
    .catch(error => dispatch(editCandidateFailure(error)))
};

/********
 *  UI
 ********/
export const openEditCandidateModal = _ => ({
  type: SHOW_CANDIDATE_DETAIL_EDIT_MODAL
});

export const closeEditCandidateModal = _ => ({
  type: CLOSE_CANDIDATE_DETAIL_EDIT_MODAL
});
