import axios from 'axios';
import {ACCESS_TOKEN, getCookie} from "../utils";

const API_ROOT = process.env.REACT_APP_API_URL;
export const V1_CANDIDATES = API_ROOT + '/v1/candidates';
export const V1_CANDIDATE_STATUS = API_ROOT + '/v1/candidate-status';
export const V1_CLIENT_COMPANIES = API_ROOT + '/v1/client-companies';
export const V1_CLIENT_COMPANIES_SIMPLE = API_ROOT + '/v1/client-companies?simple=true';
export const V1_JOB_OPENING_CANDIDATES = API_ROOT + '/v1/job-opening-candidates';
export const V1_JOB_OPENINGS = API_ROOT + '/v1/job-openings';
export const V1_JOB_OPENINGS_STATUSES = API_ROOT + '/v1/job-openings-status';
export const V1_JOB_OPENINGS_PRIORITIES = API_ROOT + '/v1/job-openings-priorities';
export const V1_LANGUAGES = API_ROOT + '/v1/languages';
export const V1_TECHNOLOGIES = API_ROOT + '/v1/technologies';
export const V1_TOKEN = API_ROOT + '/v1/token';

const buildHeaders = _ => ({
  'Content-Type': 'application/json',
  'Authorization': `Bearer ${getCookie(ACCESS_TOKEN)}`
});

/*
 * Axios wrapper for common http requests
 */
const client = {
  del: (url, id) => axios.delete(`${url}/${id}`, {headers: buildHeaders()})
    .then(response => response.data),
  get: url => axios.get(url, {headers: buildHeaders()})
    .then(response => response.data),
  getDetail: (url, id) => axios.get(`${url}/${id}`, {headers: buildHeaders()})
    .then(response => response.data),
  patch: (url, id, body) => axios.patch(url + '/' + id, body, {headers: buildHeaders()})
    .then(response => response.data),
  put: (url, body) => axios.put(url, body, {headers: buildHeaders()})
    .then(response => response.data),
  post: (url, body) => axios.post(url, body, {headers: buildHeaders()})
    .then(response => response.data),
  add: (url, id, resource, body) => axios.post(url + '/' + id + '/' + resource, body, {headers: buildHeaders()})
    .then(response => response.data)
};

/**
 * Provided an offset and a limit of results, generates the string to be appended to the request when using pagination
 *
 * @param offset - from where to start to fetch
 * @param limit - how many results we want back
 * @returns {string} - request pagination query
 */
const generateLimitAndOffsetQuery = (offset, limit) => {
  offset = offset !== undefined ? offset : '';
  limit = limit !== undefined ? limit : '';
  return `limit=${limit}&offset=${offset}`;
};

/**
 * Adds offset and limit query params to provided url.
 * Should be the first function you call when generating the url
 * since it adds a question mark (?) after the url.
 *
 * @see https://www.django-rest-framework.org/api-guide/pagination/#limitoffsetpagination
 *
 * @param url
 * @param offset
 * @param limit
 * @returns {string}
 */
export const buildUrlWithPagination = (url, offset = 0, limit) => {
  return url + '?' + generateLimitAndOffsetQuery(offset, limit);
};

const Auth = {
  login: (credentials) => {
    return client.post(V1_TOKEN, credentials);
  }
};

const Candidates = {
  list: (offset, limit) => client.get(buildUrlWithPagination(V1_CANDIDATES, offset, limit)),
  detail: (candidateId) => client.getDetail(V1_CANDIDATES, candidateId),
  create: (candidate) => client.post(V1_CANDIDATES, candidate),
  update: (candidateId, candidateNewProps) => client.patch(V1_CANDIDATES, candidateId, candidateNewProps),
  remove: (candidateId) => client.del(V1_CANDIDATES, candidateId),
  search: query => client.get(V1_CANDIDATES + '/search?query=' + query),
  applyToJobOpenings: (candidateId, jobOpenings) => client.add(V1_CANDIDATES, candidateId, 'job-openings', jobOpenings),
};

const CandidateStatus = {
  list: _ => client.get(V1_CANDIDATE_STATUS),
  detail: id => client.getDetail(V1_CANDIDATE_STATUS, id),
};

const ClientCompanies = {
  list: () => client.get(V1_CLIENT_COMPANIES_SIMPLE),
  listPaginated: (offset, limit) => client.get(buildUrlWithPagination(V1_CLIENT_COMPANIES, offset, limit)),
  create: (clientCompany) => client.post(V1_CLIENT_COMPANIES, clientCompany),
  delete: (clientCompanyId) => client.del(V1_CLIENT_COMPANIES, clientCompanyId),
};

const JobOpenings = {
  create: (jobOpening) => client.post(V1_JOB_OPENINGS, jobOpening),
  list: (offset, limit) => client.get(buildUrlWithPagination(V1_JOB_OPENINGS, offset, limit)),
  detail: (jobOpeningId) => client.getDetail(V1_JOB_OPENINGS, jobOpeningId),
  delete: (jobOpeningId) => client.del(V1_JOB_OPENINGS, jobOpeningId),
  addCandidates: (jobOpeningId, candidates) => client.add(V1_JOB_OPENINGS, jobOpeningId, 'candidates', candidates),
  search: query => client.get(V1_JOB_OPENINGS + '/search?query=' + query)
};

const JobOpeningCandidates = {
  remove: id => client.del(V1_JOB_OPENING_CANDIDATES, id),
};

const JobOpeningsStatuses = {
  list: () => client.get(V1_JOB_OPENINGS_STATUSES),
};

const JobOpeningsPriorities = {
  list: () => client.get(V1_JOB_OPENINGS_PRIORITIES),
};

const Languages = {
  list: () => client.get(V1_LANGUAGES),
};

const Technologies = {
  create: (technology) => client.post(V1_TECHNOLOGIES, technology),
  list: () => client.get(V1_TECHNOLOGIES),
};

export default {
  Auth,
  Candidates,
  CandidateStatus,
  ClientCompanies,
  JobOpenings,
  JobOpeningCandidates,
  JobOpeningsStatuses,
  JobOpeningsPriorities,
  Languages,
  Technologies,
};
