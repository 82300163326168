import React from 'react';
import {Form, Grid} from "semantic-ui-react";
import TechnologiesDropdown from "../../technologies-dropdown/technologies-dropdown";
import LanguagesDropdown from "../../languages/languages-dropdown";

export class CandidateForm extends React.Component {
  render() {
    const {onChange, onSubmit, candidate = {}, isEdit = false} = this.props;
    return (
      <Form success={false} onSubmit={onSubmit}>
        <Form.Group widths='equal'>
          <Form.Input fluid
                      name={'firstName'}
                      label={'Nombre'}
                      onChange={onChange}
                      placeholder={'Nombre'}
                      value={candidate.firstName || ''}
          />
          <Form.Input fluid
                      name={'lastName'}
                      label={'Apellido'}
                      onChange={onChange}
                      value={candidate.lastName || ''}
                      placeholder={'Apellido'}
          />
          <Form.Input fluid
                      name={'jobTitle'}
                      label={'Título'}
                      onChange={onChange}
                      placeholder={'Título'}
                      value={candidate.jobTitle || ''}
          />
        </Form.Group>
        <Form.Group widths='equal'>
          <Form.Input fluid disabled={isEdit}
                      name={'email'}
                      label={'Email'}
                      onChange={onChange}
                      placeholder={'Email'}
                      value={candidate.email || ''}
          />
          <Form.Input fluid
                      name={'phoneNumber'}
                      onChange={onChange}
                      label={'Teléfono'}
                      placeholder={'Teléfono'}
                      value={candidate.phoneNumber || ''}
          />
          <Form.Input fluid
                      name={'linkedinProfileUrl'}
                      onChange={onChange}
                      label={'Perfil de LinkedIn'}
                      placeholder={'Perfil de LinkedIn'}
                      value={candidate.linkedinProfileUrl || ''}
          />
        </Form.Group>

        <Grid centered columns={3}>
          <Grid.Row style={{paddingBottom: '3px'}}>
            <Grid.Column width={8} style={{fontWeight: '700', fontSize: '.93em'}}>
              {'Tecnologías'}
            </Grid.Column>
            <Grid.Column width={4} style={{fontWeight: '700', fontSize: '.93em'}}>
              {'Años de experiencia'}
            </Grid.Column>
            <Grid.Column width={4} style={{fontWeight: '700', fontSize: '.93em'}}>
              {'Idiomas'}
            </Grid.Column>
          </Grid.Row>


          {/* Technologies */}
          <Grid.Row style={{paddingBottom: '3px', paddingTop: 'unset'}}>
            <Grid.Column width={8}>
              <TechnologiesDropdown
                name={'technologies'}
                onChange={onChange}
                value={candidate.technologies[0] !== undefined && candidate.technologies[0].id}
              />
            </Grid.Column>
            <Grid.Column width={4}>
              <Form.Input
                name={'yearsOfExperience'}
                onChange={onChange}
                value={
                  candidate.technologies[0] !== undefined && candidate.technologies[0].yearsOfExperience ?
                    candidate.technologies[0].yearsOfExperience :
                    ''
                }
              />
            </Grid.Column>

            {/* Idiomas */}
            <Grid.Column width={4}>
              <LanguagesDropdown
                name={'languages'}
                onChange={onChange}
                value={
                  candidate.languages[0] !== undefined &&
                  candidate.languages[0].id !== undefined &&
                  candidate.languages[0].id
                }
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row style={{paddingBottom: '3px', paddingTop: 'unset'}}>
            <Grid.Column width={16}>
              <Form.TextArea
                name={'notes'}
                onChange={onChange}
                label={'Notas'}
                placeholder={'Notas'}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Form.Button positive>{isEdit ? 'Actualizar candidato' : 'Añadir candidato'}</Form.Button>
          </Grid.Row>
        </Grid>
      </Form>
    )
  }
}
