import React from 'react';
import {Container, Pagination, Segment} from "semantic-ui-react";
import JobOpeningsTable from "./components/job-openings-table";
import SearchBar from "../../components/search-bar";
import {connect} from "react-redux";
import {fetchJobOpenings, openJobOpeningCreateModal} from "./actions";
import CreateJobOpeningModal from "./components/create-job-opening-modal";

export class JobOpeningsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
      totalPages: 5,
      jobOpeningsPerPage: 10
    };
  }

  componentDidMount() {
    this.fetchJobOpeningsAndUpdatePagination();
  }

  handlePaginationChange = (event, {activePage}) => {
    const offset = this.state.jobOpeningsPerPage * (activePage - 1);
    this.fetchJobOpeningsAndUpdatePagination(offset, activePage);
  };

  fetchJobOpeningsAndUpdatePagination = (offset = 0, activePage = 1) => {
    this.props.fetchJobOpenings(offset)
      .then(_ => {
        this.setState({
          activePage: activePage,
          totalPages: Math.ceil(this.props.jobOpeningsCount / this.state.jobOpeningsPerPage)
        });
      });
  };

  render() {
    return (
      <Container style={{marginTop: '1em'}}>
        <SearchBar title={'Búsquedas'} addButtonAction={this.props.openJobOpeningCreateModal}/>
        <JobOpeningsTable jobOpenings={this.props.jobOpenings}/>
        <Segment basic floated={'right'}>
          <Pagination
            activePage={this.state.activePage}
            totalPages={this.state.totalPages}
            onPageChange={this.handlePaginationChange}
            boundaryRange={0}
            siblingRange={2}
            style={{margin: '0 3em 3em 3em'}}
          />
        </Segment>
        <CreateJobOpeningModal />
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  jobOpenings: state.jobOpenings.items,
  jobOpeningsCount: state.jobOpenings.count,
});

const mapDispatchToProps = {
  fetchJobOpenings,
  openJobOpeningCreateModal
};

export default connect(mapStateToProps, mapDispatchToProps)(JobOpeningsPage);
