import {
  CANDIDATE_SEARCH,
  CANDIDATE_SEARCH_FAILURE,
  CANDIDATE_SEARCH_RESET_STATUS,
  CANDIDATE_SEARCH_SELECT_CANDIDATE, CANDIDATE_SEARCH_SUCCESS,
  CANDIDATE_SEARCH_UNSELECT_CANDIDATE
} from "./action-types";

const initialState = {
  pending: false,
  done: false,
  error: false,
  results: [],
  selected: [],
  currentQuery: undefined,
};

export const candidateSearchReducer = (state = initialState, action) => {
  switch (action.type) {
    case CANDIDATE_SEARCH:
      return {
        ...state,
        ...initialState,
        pending: true,
        currentQuery: action.payload.searchQuery
      };
    case CANDIDATE_SEARCH_SUCCESS:
      return {
        ...state,
        pending: false,
        results: action.payload.candidates
      };
    case CANDIDATE_SEARCH_FAILURE:
      return {
        ...initialState,
        error: action.payload.error
      };
    case CANDIDATE_SEARCH_RESET_STATUS:
      return initialState;
    case CANDIDATE_SEARCH_SELECT_CANDIDATE:
      return {
        ...state,
        selected: [
          ...state.selected,
          ...state.results.filter(rc =>
            action.payload.candidates.some(cid => cid === rc.id && !state.selected.some(sc => sc.id === cid))
          )
        ]
      };
    case CANDIDATE_SEARCH_UNSELECT_CANDIDATE:
      return {
        ...state,
        selected: [
          ...state.selected.filter(candidate => candidate.id !== action.payload.candidateId),
        ]
      };
    default:
      return state;
  }
};
