import authReducer from "./reducers/auth-reducer";
import {candidatesReducer, candidatesUIReducer} from "../pages/candidates/reducer";
import {combineReducers} from "redux";
import {connectRouter} from 'connected-react-router'
import {clientCompaniesReducer, clientCompaniesUIReducer} from "../pages/client-companies/reducer";
import {
  jobOpeningsPrioritiesReducer,
  jobOpeningsReducer,
  jobOpeningsStatusesReducer,
  jobOpeningsUIReducer
} from "../pages/job-openings/reducer";
import {technologiesReducer} from "../components/technologies-dropdown/reducer";
import {languagesReducer} from "../components/languages/reducers";
import {candidateDetailsReducer, candidateDetailsUIReducer} from "../pages/candidate-details/reducer";
import {jobOpeningDetailReducer, jobOpeningDetailUIReducer} from "../pages/job-opening-details/reducer";
import {candidateStatusesReducer} from "../components/candidate-status-dropdown/reducer";
import {candidateSearchReducer} from "../components/candidates-search/reducer";
import {candidateToJobsReducer} from "../components/apply-candidate-to-jobs-modal/reducer";
import {jobOpeningSearchReducer} from "../components/job-openings-search/reducer";

const rootReducer = history => combineReducers({
  auth: authReducer,
  candidateDetails: candidateDetailsReducer,
  candidateDetailsUI: candidateDetailsUIReducer,
  candidateSearch: candidateSearchReducer,
  candidateStatuses: candidateStatusesReducer,
  candidateToJobsReducer: candidateToJobsReducer,
  candidates: candidatesReducer,
  candidatesUI: candidatesUIReducer,
  clientCompanies: clientCompaniesReducer,
  clientCompaniesUI: clientCompaniesUIReducer,
  jobOpeningDetail: jobOpeningDetailReducer,
  jobOpeningDetailUI: jobOpeningDetailUIReducer,
  jobOpeningSearch: jobOpeningSearchReducer,
  jobOpenings: jobOpeningsReducer,
  jobOpeningsUI: jobOpeningsUIReducer,
  jobOpeningsStatuses: jobOpeningsStatusesReducer,
  jobOpeningsPriorities: jobOpeningsPrioritiesReducer,
  languages: languagesReducer,
  technologies: technologiesReducer,
  router: connectRouter(history),
});

export default rootReducer;
