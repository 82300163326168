import _ from "lodash";

export function handleFormChange(event, data) {
  if ("yearsOfExperience" === data.name) {
    this.setState({
      candidate: {
        ...this.state.candidate,
        technologies: [{
          ...this.state.candidate.technologies[0],
          yearsOfExperience: _.toSafeInteger(data.value)
        }]
      }
    });
  } else if ('technologies' === data.name) {
    this.setState({
      candidate: {
        ...this.state.candidate,
        technologies: [{
          ...this.state.candidate.technologies[0],
          id: data.value,
        }]
      }
    });
  } else if ('languages' === data.name) {
    this.setState({
      candidate: {
        ...this.state.candidate,
        languages: [data.value]
      }
    });
  } else {
    this.setState({
      candidate: {
        ...this.state.candidate,
        [event.target.name || data.name]: event.target.value || data.value
      }
    });
  }
}