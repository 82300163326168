import React from 'react';
import _ from 'lodash';
import {Form, Icon, Message, Modal} from "semantic-ui-react";
import {connect} from "react-redux";
import {addClientCompany, closeClientCompanyCreateModal} from "../actions";

export class CreateClientCompanyModal extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event, data) {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  handleSubmit(event) {
    event.preventDefault();

    const clientCompany = {
      name: _.toString(this.state.name),
      email: _.toString(this.state.email),
      phoneNumber: _.toString(this.state.phoneNumber),
      address: _.toString(this.state.address),
    };
    this.props.addClientCompany(clientCompany);
  }

  render() {
    return (
      <Modal
        open={this.props.showClientCompanyCreateModal}
        onClose={this.props.closeClientCompanyCreateModal}
        centered closeIcon dimmer={'blurring'}>
        <Modal.Header>{'Añadir cliente'}</Modal.Header>
        <Modal.Content>

          {/* Create client company form */}
          <Form success={false} onSubmit={this.handleSubmit}>
            <Form.Input
              required
              name={'name'}
              onChange={this.handleChange}
              label={'Nombre'}
              placeholder={'Nombre'}
              fluid
            />
            <Form.Input
              required
              name={'email'}
              onChange={this.handleChange}
              label={'Email'}
              placeholder={'Email'}
              fluid
            />
            <Form.Input
              name={'phoneNumber'}
              onChange={this.handleChange}
              label={'Teléfono'}
              fluid
              placeholder={'Teléfono'}
            />
            <Form.Input
              required
              name={'address'}
              onChange={this.handleChange}
              fluid
              label={'Dirección'}
              placeholder={'Dirección'}
            />
            <Form.Button positive>{'Añadir cliente'}</Form.Button>

            <Message icon hidden={!this.props.creatingClientCompany} color={'orange'}>
              <Icon name='circle notched' loading/>
              <Message.Content>
                <Message.Header>{'Añadiendo cliente..'}</Message.Header>
              </Message.Content>
            </Message>
            <Message icon hidden={!this.props.clientCompanyCreated} positive>
              <Message.Content>
                <Message.Header>{'Cliente creado!'}</Message.Header>
                {'Ya podés volver a la tabla de clientes.'}
              </Message.Content>
            </Message>
            <Message icon hidden={!this.props.clientCompanyCreationError} color={'red'}>
              <Message.Content>
                <Message.Header>{'Ocurrio un error al intentar añadir al cliente.'}</Message.Header>
                {'Asegurate de que no exista un cliente con el mismo nombre.'}
              </Message.Content>
            </Message>
          </Form>


        </Modal.Content>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  creatingClientCompany: state.clientCompanies.create.pending,
  clientCompanyCreated: state.clientCompanies.create.done,
  clientCompanyCreationError: state.clientCompanies.create.error,
  showClientCompanyCreateModal: state.clientCompaniesUI.showClientCompanyCreateModal
});

const mapDispatchToProps = {
  addClientCompany,
  closeClientCompanyCreateModal
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateClientCompanyModal);
