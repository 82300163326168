import React from 'react';
import {Confirm} from "semantic-ui-react";
import {connect} from "react-redux";
import {closeCandidateDeleteModal, deleteCandidate} from "../actions";

class CandidateDeleteModal extends React.Component {
  render() {
    const {closeCandidateDeleteModal, deleteCandidate, candidateId} = this.props;
    return (
      <Confirm
        open={this.props.showCandidateDeleteModal}
        size={'mini'}
        confirmButton={'Eliminar'}
        cancelButton={'Cancelar'}
        content={'¿Estas seguro de eliminar?'}
        onCancel={closeCandidateDeleteModal}
        onConfirm={() => deleteCandidate(candidateId)}
      />
    )
  }
}

const mapStateToProps = state => ({
  showCandidateDeleteModal: state.candidatesUI.showCandidateDeleteModal,
  candidateId: state.candidates.delete.currentId
});

const mapDispatchToProps = {
  deleteCandidate,
  closeCandidateDeleteModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(CandidateDeleteModal);
