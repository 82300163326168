import {
  JOB_OPENING_CLOSE_CREATE_MODAL,
  JOB_OPENING_CLOSE_DELETE_MODAL,
  JOB_OPENING_CREATE,
  JOB_OPENING_CREATE_DONE,
  JOB_OPENING_CREATE_ERROR,
  JOB_OPENING_CREATE_RESET_STATE,
  JOB_OPENING_DELETE,
  JOB_OPENING_DELETE_FAILURE, JOB_OPENING_DELETE_SET_ID,
  JOB_OPENING_DELETE_SUCCESS,
  JOB_OPENING_PRIORITIES_REQUEST,
  JOB_OPENING_PRIORITIES_REQUEST_DONE,
  JOB_OPENING_PRIORITIES_REQUEST_ERROR,
  JOB_OPENING_REQUEST,
  JOB_OPENING_REQUEST_DONE,
  JOB_OPENING_REQUEST_ERROR,
  JOB_OPENING_OPEN_CREATE_MODAL,
  JOB_OPENING_SHOW_DELETE_MODAL,
  JOB_OPENING_STATUSES_REQUEST,
  JOB_OPENING_STATUSES_REQUEST_DONE,
  JOB_OPENING_STATUSES_REQUEST_ERROR
} from "./action-types";

/*************************
 *  jobOpeningsUIReducer
 *************************/
export const jobOpeningsUIInitialState = {
  showJobOpeningCreateModal: false,
  showJobOpeningDeleteModal: false,
};

export const jobOpeningsUIReducer = (state = jobOpeningsUIInitialState, action) => {
  switch (action.type) {
    case JOB_OPENING_OPEN_CREATE_MODAL:
      return {
        ...state,
        showJobOpeningCreateModal: true,
      };
    case JOB_OPENING_CLOSE_CREATE_MODAL:
      return {
        ...state,
        showJobOpeningCreateModal: false,
      };
    case JOB_OPENING_SHOW_DELETE_MODAL:
      return {
        ...state,
        showJobOpeningDeleteModal: true
      };
    case JOB_OPENING_CLOSE_DELETE_MODAL:
      return {
        ...state,
        showJobOpeningDeleteModal: false
      };
    default:
      return state;
  }
};

/***********************
 *  jobOpeningsReducer
 ***********************/
export const jobOpeningsInitialState = {
  // create: {
  //   pending: false,
  //   completed: false,
  //   error: false,
  // },
  // fetch: {
  //   pending: false,
  //   completed: false,
  //   error: false
  // },
  delete: {
    currentId: undefined,
    pending: false,
    done: false,
    error: false,
  },
  // update: {
  //   pending: false,
  //   completed false,
  //   error: false
  // },
  items: [],
  count: 0,
  isFetching: false,
  creatingJobOpening: false,
  jobOpeningCreated: false,
  jobOpeningCreationError: false,
  jobOpeningFetchError: false,
};

export const jobOpeningsReducer = (state = jobOpeningsInitialState, action) => {
  switch (action.type) {
    /************************
     *  JOB_OPENING_CREATE
     ************************/
    case JOB_OPENING_CREATE:
      return {
        ...state,
        creatingJobOpening: true,
        jobOpeningCreated: false,
        jobOpeningCreationError: false,
      };
    case JOB_OPENING_CREATE_DONE:
      return {
        ...state,
        count: state.count + 1,
        items: [
          ...state.items,
          action.payload.jobOpening
        ],
        creatingJobOpening: false,
        jobOpeningCreated: true,
      };
    case JOB_OPENING_CREATE_RESET_STATE:
      return {
        ...state,
        creatingJobOpening: false,
        jobOpeningCreated: false,
        jobOpeningCreationError: false,
      };
    case JOB_OPENING_CREATE_ERROR:
      return {
        ...state,
        creatingJobOpening: false,
        jobOpeningCreationError: true
      };

    /************************
     *  JOB_OPENING_REQUEST
     ************************/
    case JOB_OPENING_REQUEST:
      return {
        ...state,
        isFetching: true,
        jobOpeningFetchError: false
      };
    case JOB_OPENING_REQUEST_DONE:
      return {
        ...state,
        isFetching: false,
        items: action.payload.items,
        count: action.payload.count,
      };
    case JOB_OPENING_REQUEST_ERROR:
      return {
        ...state,
        isFetching: false,
        jobOpeningFetchError: true,
      };

    /***********************
     *  JOB_OPENING_DELETE
     ***********************/
    case JOB_OPENING_DELETE:
      return {
        ...state,
        delete: {
          ...state.delete,
          pending: true
        }
      };
    case JOB_OPENING_DELETE_SUCCESS:
      return {
        ...state,
        items: state.items.filter(candidate => candidate.id !== action.payload.jobOpeningId),
        count: state.count - 1,
        delete: {
          ...state.delete,
          pending: false,
          done: true
        },
      };
    case JOB_OPENING_DELETE_FAILURE:
      return {
        ...state,
        delete: {
          ...state.delete,
          pending: false,
          error: true
        },
      };
    case JOB_OPENING_DELETE_SET_ID:
      return {
        ...state,
        delete: {
          ...state.delete,
          currentId: action.payload.jobOpeningId
        }
      };
    default:
      return state;
  }
};


/*******************************
 *  jobOpeningsStatusesReducer
 *******************************/
const jobOpeningsStatusesReducerInitialState = {
  items: [],
  isFetching: false,
  fetchError: false
};

export const jobOpeningsStatusesReducer = (state = jobOpeningsStatusesReducerInitialState, action) => {
  switch (action.type) {
    case JOB_OPENING_STATUSES_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case JOB_OPENING_STATUSES_REQUEST_DONE:
      return {
        ...state,
        isFetching: false,
        items: action.payload.items
      };
    case JOB_OPENING_STATUSES_REQUEST_ERROR:
      return {
        ...state,
        fetchError: true
      };
    default:
      return state;
  }
};

/*********************************
 *  jobOpeningsPrioritiesReducer
 *********************************/
const jobOpeningsPrioritiesReducerInitialState = {
  items: [],
  isFetching: false,
  fetchError: false
};

export const jobOpeningsPrioritiesReducer = (state = jobOpeningsPrioritiesReducerInitialState, action) => {
  switch (action.type) {
    case JOB_OPENING_PRIORITIES_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case JOB_OPENING_PRIORITIES_REQUEST_DONE:
      return {
        ...state,
        isFetching: false,
        items: action.payload.items
      };
    case JOB_OPENING_PRIORITIES_REQUEST_ERROR:
      return {
        ...state,
        fetchError: true
      };
    default:
      return state;
  }
};
