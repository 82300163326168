import {Dropdown, Form, Grid} from "semantic-ui-react";
import React from "react";

export const DetailsRow = ({statuses, priorities, onChange}) => (
  <Grid.Row columns={4}>
    <Grid.Column>
      <div className={'label'}>
        {'Prioridad'}
      </div>
      <Dropdown
        selection scrolling fluid
        name={'priority'}
        onChange={onChange}
        placeholder={'Prioridad'}
        noResultsMessage={'No se encontraron resultados.'}
        options=
          {
            priorities
              ? priorities.map(priority => {
                return {
                  key: priority.id,
                  value: priority.id,
                  text: priority.priority,
                };
              })
              : []
          }
      />
    </Grid.Column>
    <Grid.Column>
      <div className={'label'}>
        {'Estado'}
      </div>
      <Dropdown
        selection scrolling fluid
        name={'status'}
        onChange={onChange}
        options=
          {
            statuses
              ? statuses.map(status => ({
                key: status.id,
                value: status.id,
                text: status.status,
              }))
              : []
          }
        placeholder={'Estado'}
        noResultsMessage={'No se encontraron resultados.'}
      />
    </Grid.Column>
    <Grid.Column>
      <div className={'label'}>
        {'Salario Mínimo'}
      </div>
      <Form.Input
        name={'minSalary'}
        placeholder={'Salario Mínimo'}
        onChange={onChange}
      />
    </Grid.Column>
    <Grid.Column>
      <div className={'label'}>
        {'Salario Máximo'}
      </div>
      <Form.Input
        name={'maxSalary'}
        placeholder={'Salario Máximo'}
        onChange={onChange}
      />
    </Grid.Column>
  </Grid.Row>
);
