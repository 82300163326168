import {TECHNOLOGY_REQUEST, TECHNOLOGY_REQUEST_DONE, TECHNOLOGY_REQUEST_ERROR} from "./action-types";

const technologiesUIReducerInitialState = {
  items: [],
  count: 0
};

export const technologiesUIReducer = (state = technologiesUIReducerInitialState, action) => {
};

const technologiesReducerInitialState = {
  items: [],
  count: 0,
  isFetching: false,
  fetchError: false,
  isCreating: false,
};

export const technologiesReducer = (state = technologiesReducerInitialState, action) => {
  switch (action.type) {
    case TECHNOLOGY_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case TECHNOLOGY_REQUEST_DONE:
      return {
        ...state,
        isFetching: false,
        items: action.payload.items,
        count: action.payload.count
      };
    case TECHNOLOGY_REQUEST_ERROR:
      return {
        ...state,
        fetchError: true,
      };
    default:
      return state;
  }
};
