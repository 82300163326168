import {Form, Grid} from "semantic-ui-react";
import React from "react";

export const JobOpeningTitleRow = ({onChange}) => (
  <Grid.Row columns={1}>
    <Grid.Column>
      <Form.Input
        name={'name'}
        onChange={onChange}
        label={'Nombre de la búsqueda'}
        placeholder={'Nombre de la búsqueda'}
        fluid
      />
    </Grid.Column>
  </Grid.Row>
);
