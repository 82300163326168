import {
  ASSOCIATE_CANDIDATE_TO_JOB_OPENINGS,
  ASSOCIATE_CANDIDATE_TO_JOB_OPENINGS_FAILURE,
  ASSOCIATE_CANDIDATE_TO_JOB_OPENINGS_SUCCESS,
  CLOSE_APPLY_CANDIDATE_TO_JOBS_MODAL,
  SHOW_APPLY_CANDIDATE_TO_JOBS_MODAL
} from "./action-types";
import agent from "../../api_client/api-client";


const applyToJobOpenings = _ => ({
  type: ASSOCIATE_CANDIDATE_TO_JOB_OPENINGS,
});

const applyToJobOpeningsSuccess = response => ({
  type: ASSOCIATE_CANDIDATE_TO_JOB_OPENINGS_SUCCESS,
  payload: {
    jobOpenings: response
  }
});

const applyToJobOpeningsFailure = error => ({
  type: ASSOCIATE_CANDIDATE_TO_JOB_OPENINGS_FAILURE,
  payload: {
    error: error.toString()
  }
});

export const associateCandidateToJobOpenings = (candidateId, jobOpenings) => dispatch => {
  dispatch(applyToJobOpenings());
  agent.Candidates.applyToJobOpenings(candidateId, jobOpenings)
    .then(response => dispatch(applyToJobOpeningsSuccess(response)))
    .catch(error => dispatch(applyToJobOpeningsFailure(error)));
};

export const showApplyCandidateToJobsModal = candidateId => ({
  type: SHOW_APPLY_CANDIDATE_TO_JOBS_MODAL,
  payload: {
    candidateId
  }
});

export const closeApplyCandidateToJobsModal = _ => ({
  type: CLOSE_APPLY_CANDIDATE_TO_JOBS_MODAL
});
