import React from 'react';
import {Button, Table} from "semantic-ui-react";
import DeleteJobOpeningModal from "./delete-job-opening-modal";
import {closeJobOpeningDeleteModal, openDeleteModalAndSetCurrentId} from "../actions";
import {connect} from "react-redux";
import {Link} from "react-router-dom";

export class JobOpeningsTable extends React.Component {
  headers = [
    'Acciones',
    'Nombre',
    'Cliente',
    'Empresa',
    'Prioridad',
    'Estado',
    'Salario mínimo',
    'Salario máximo',
  ];

  render() {
    return (
      <>
        <Table basic compact={'very'} size={'small'}>
          <Table.Header>
            <Table.Row>
              {
                this.headers.map((header) =>
                  <Table.HeaderCell key={header} textAlign='center'>{header}</Table.HeaderCell>)
              }
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {
              this.props.jobOpenings && this.props.jobOpenings.map(jobOpening => {
                return (
                  <Table.Row
                    key={jobOpening.id}
                    positive={jobOpening.priority === 'Baja'}
                    warning={jobOpening.priority === 'Media'}
                    negative={jobOpening.priority === 'Alta'}>
                    <Table.Cell collapsing textAlign={'center'}>
                      <Button icon={'edit'} circular size={'mini'} color={'yellow'}/>
                      <Button
                        as={Link} to={`job-openings/${jobOpening.id}`} target='_blank'
                        icon={'user'} circular size={'mini'} color={'instagram'}
                      />
                      <Button
                        icon={'trash'} circular size={'mini'} color={'red'}
                        onClick={() => this.props.openDeleteModalAndSetCurrentId(jobOpening.id)}
                      />
                    </Table.Cell>
                    <Table.Cell collapsing textAlign={'center'}>{jobOpening.name}</Table.Cell>
                    <Table.Cell collapsing textAlign={'center'}>{jobOpening.clientCompany}</Table.Cell>
                    <Table.Cell collapsing textAlign={'center'}>{jobOpening.finalCompany}</Table.Cell>
                    <Table.Cell collapsing textAlign={'center'}>{jobOpening.priority}</Table.Cell>
                    <Table.Cell collapsing textAlign={'center'}>{jobOpening.status}</Table.Cell>
                    <Table.Cell collapsing textAlign={'center'}>{jobOpening.minSalary}</Table.Cell>
                    <Table.Cell collapsing textAlign={'center'}>{jobOpening.maxSalary}</Table.Cell>
                  </Table.Row>
                );
              })
            }
          </Table.Body>
        </Table>
        <DeleteJobOpeningModal/>
      </>
    )
  }
}

const
  mapDispatchToProps = {
    openDeleteModalAndSetCurrentId,
    closeJobOpeningDeleteModal,
  };

const mapStateToProps = state => ({
  deletingJobOpening: state.jobOpenings.delete.pending,
  jobOpeningDeleted: state.jobOpenings.delete.completed,
  jobOpeningDeleteError: state.jobOpenings.delete.error,
});

export default connect(mapStateToProps, mapDispatchToProps)(JobOpeningsTable);
