import agent from "../../api_client/api-client";
import _ from 'lodash';

import {
  CANDIDATE_CREATE,
  CANDIDATE_CREATE_FAILURE,
  CANDIDATE_CREATE_RESET_STATUS,
  CANDIDATE_CREATE_SUCCESS,
  CANDIDATE_DELETE,
  CANDIDATE_DELETE_FAILURE,
  CANDIDATE_DELETE_RESET_STATUS,
  CANDIDATE_DELETE_SET_CURRENT_ID,
  CANDIDATE_DELETE_SUCCESS,
  CANDIDATE_FETCH,
  CANDIDATE_FETCH_FAILURE,
  CANDIDATE_FETCH_RESET_STATUS,
  CANDIDATE_FETCH_SUCCESS,
  CANDIDATE_UPDATE,
  CANDIDATE_UPDATE_FAILURE,
  CANDIDATE_UPDATE_RESET_STATUS,
  CANDIDATE_UPDATE_SUCCESS,
  CLOSE_CANDIDATE_CREATE_MODAL,
  CLOSE_CANDIDATE_DELETE_MODAL,
  CLOSE_CANDIDATE_DETAIL_MODAL,
  OPEN_CANDIDATE_CREATE_MODAL,
  OPEN_CANDIDATE_DELETE_MODAL,
  OPEN_CANDIDATE_DETAIL_MODAL,
} from "./action-types";

/**************
 *     UI
 **************/
export const openCandidateCreateModal = () => dispatch => {
  dispatch(resetCreateCandidateStatus());
  dispatch({
    type: OPEN_CANDIDATE_CREATE_MODAL
  });
};

export const closeCandidateCreateModal = () => ({
  type: CLOSE_CANDIDATE_CREATE_MODAL
});

export const openCandidateDetailModal = candidateId => ({
  type: OPEN_CANDIDATE_DETAIL_MODAL,
  candidateId: candidateId
});

export const closeCandidateDetailModal = () => ({
  type: CLOSE_CANDIDATE_DETAIL_MODAL,
});

export const openCandidateDeleteModalAndUpdateCurrentId = candidateId => dispatch => {
  dispatch(updateCandidateDeleteId(candidateId));
  dispatch(openCandidateDeleteModal());
};

export const openCandidateDeleteModal = () => ({
  type: OPEN_CANDIDATE_DELETE_MODAL
});

export const closeCandidateDeleteModal = () => ({
  type: CLOSE_CANDIDATE_DELETE_MODAL,
});

/*************
 *  Entities
 *************/
export const fetchCandidates = offset => dispatch => {
  dispatch(resetFetchCandidatesStatus());
  dispatch(requestCandidates());
  return agent.Candidates.list(offset)
    .then(response => dispatch(receiveCandidates(response.results, response.count)))
    .catch(error => dispatch(requestCandidatesError(error)));
};

export const addCandidate = candidate => dispatch => {
  dispatch(createCandidate());
  return agent.Candidates.create(candidate)
    .then(
      response => {
        dispatch(createCandidateSuccess(response));
        dispatch(closeCandidateCreateModal());
      })
    .catch(
      error => dispatch(createCandidateError(error))
    );
};

export const deleteCandidate = candidateId => dispatch => {
  dispatch(resetRemoveCandidateStatus());
  dispatch(removeCandidate(candidateId));
  return agent.Candidates.remove(candidateId)
    .then(_ => {
      dispatch(removeCandidateSuccess(candidateId));
      dispatch(closeCandidateDeleteModal());
    })
    .catch(error => dispatch(removeCandidateError(error)));
};

export const modifyCandidate = (candidateId, candidateNewProps) => dispatch => {
  dispatch(resetUpdateCandidateStatus());
  dispatch(updateCandidate());
  return agent.Candidates.update(candidateId, candidateNewProps)
    .then(response => dispatch(updateCandidateSuccess(candidateId, response)))
    .catch(error => dispatch(updateCandidateError(error)));
};

const formatFullName = (firstName, lastName) => {
  return firstName + ' ' + lastName;
};

const formatLanguages = languages => {
  if (languages && languages.length) {
    return languages
      .map(language => language.name + ' - ' + language.level)
  }
  return '';
};

export const formatCandidate = candidate => {
  const sortedTechnologies = candidate.technologies.sort((a, b) => a.yearsOfExperience > b.yearsOfExperience);
  return {
    ...candidate,
    fullName: formatFullName(candidate.firstName, candidate.lastName),
    languages: formatLanguages(candidate.languages),
    mainTechnology: _.isArray(sortedTechnologies) && sortedTechnologies.length > 0 ? sortedTechnologies[0] : undefined,
    otherTechnologies: _.isArray(sortedTechnologies) && sortedTechnologies.length > 1 ? sortedTechnologies.slice(1) : [],
    lastContactAt: candidate.lastContactAt,
    linkedinProfileUrl: candidate.linkedinProfileUrl !== undefined ? candidate.linkedinProfileUrl : '',
  }
};

const requestCandidates = _ => ({
  type: CANDIDATE_FETCH,
});

const receiveCandidates = (candidates, candidatesCount) => ({
  type: CANDIDATE_FETCH_SUCCESS,
  payload: {
    candidates: candidates.map(candidate => formatCandidate(candidate)),
    candidatesCount: candidatesCount
  }
});

const requestCandidatesError = error => ({
  type: CANDIDATE_FETCH_FAILURE,
  error: error.toString()
});

const resetFetchCandidatesStatus = _ => ({
  type: CANDIDATE_FETCH_RESET_STATUS,
});

// Create candidate actions
const createCandidate = candidate => ({
  type: CANDIDATE_CREATE,
});

const createCandidateSuccess = candidate => ({
  type: CANDIDATE_CREATE_SUCCESS,
  payload: {
    candidate: formatCandidate(candidate)
  }
});

const createCandidateError = error => ({
  type: CANDIDATE_CREATE_FAILURE,
  error: error.toString()
});

const resetCreateCandidateStatus = () => ({
  type: CANDIDATE_CREATE_RESET_STATUS
});

// Update candidate actions
const updateCandidate = _ => ({
  type: CANDIDATE_UPDATE,
});

const updateCandidateSuccess = (candidateId, updatedCandidate) => ({
  type: CANDIDATE_UPDATE_SUCCESS,
  payload: {
    candidateId,
    updatedCandidate: formatCandidate(updatedCandidate)
  }
});

const updateCandidateError = error => ({
  type: CANDIDATE_UPDATE_FAILURE,
  error: error.toString()
});

const resetUpdateCandidateStatus = () => ({
  type: CANDIDATE_UPDATE_RESET_STATUS
});

// Delete candidate actions
const removeCandidate = (candidateId) => ({
  type: CANDIDATE_DELETE,
  payload: {
    candidateId: candidateId
  }
});

const removeCandidateSuccess = (candidateId) => ({
  type: CANDIDATE_DELETE_SUCCESS,
  payload: {
    candidateId: candidateId
  }
});

const removeCandidateError = (error) => ({
  type: CANDIDATE_DELETE_FAILURE,
  error: error.toString()
});

const resetRemoveCandidateStatus = () => ({
  type: CANDIDATE_DELETE_RESET_STATUS
});

const updateCandidateDeleteId = candidateId => ({
  type: CANDIDATE_DELETE_SET_CURRENT_ID,
  payload: {
    candidateId: candidateId
  }
});
