import agent from "../../api_client/api-client";
import {
  TECHNOLOGY_CLOSE_CREATE_MODAL,
  TECHNOLOGY_CREATE,
  TECHNOLOGY_CREATE_DONE,
  TECHNOLOGY_CREATE_ERROR,
  TECHNOLOGY_CREATE_RESET_STATE,
  TECHNOLOGY_REQUEST,
  TECHNOLOGY_REQUEST_DONE,
  TECHNOLOGY_REQUEST_ERROR
} from "./action-types";

/********************
 *  Technologies UI
 ********************/
const closeTechnologyCreateModal = _ => {
  return {
    type: TECHNOLOGY_CLOSE_CREATE_MODAL
  }
};

/************************
 *  Technologies State
 ************************/
export const addTechnology = jobOpening => dispatch => {
  dispatch(createJobOpening());
  return agent.Technologies.create(jobOpening)
    .then(response => {
      dispatch(receiveCreatedTechnology(response));
      setTimeout(() => {
        dispatch(resetTechnologyCreateState());
        dispatch(closeTechnologyCreateModal());
      }, 3000)
    })
    .catch(error =>
      dispatch(technologyCreationError(error))
    );
};

export const fetchTechnologies = () => dispatch => {
  dispatch(requestTechnologies());
  return agent.Technologies.list()
    .then(response => {
      dispatch(receiveRequestedTechnologies(response));
      return response;
    })
    .catch(error =>
      dispatch(requestTechnologiesError(error))
    );
};

const createJobOpening = () => {
  return {
    type: TECHNOLOGY_CREATE
  };
};

const receiveCreatedTechnology = response => {
  return {
    type: TECHNOLOGY_CREATE_DONE,
    payload: {
      technology: response
    }
  };
};

const resetTechnologyCreateState = _ => {
  return {
    type: TECHNOLOGY_CREATE_RESET_STATE
  };
};

const technologyCreationError = error => {
  return {
    type: TECHNOLOGY_CREATE_ERROR,
    error: error.toString()
  }
};

const requestTechnologies = _ => {
  return {
    type: TECHNOLOGY_REQUEST
  };
};

const receiveRequestedTechnologies = response => {
  return {
    type: TECHNOLOGY_REQUEST_DONE,
    payload: {
      items: response,
    }
  };
};

const requestTechnologiesError = error => {
  return {
    type: TECHNOLOGY_REQUEST_ERROR,
    error: error.toString()
  };
};
