import React from 'react';
import {Dimmer, Loader} from "semantic-ui-react";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {compose} from "redux";
import JobOpening from "./components/job-opening-details";
import {fetchJobOpeningDetail} from "./actions";
import AddCandidateModal from "./components/add-candidate-modal";

export class JobOpeningDetailsPage extends React.Component {
  componentDidMount() {
    this.props.fetchJobOpeningDetail(this.props.match.params.jobOpeningId);
  }

  render() {
    return (
      this.props.jobOpeningFetched ?
        <>
          <JobOpening jobOpening={this.props.jobOpening}/>
          <AddCandidateModal jobOpeningId={this.props.jobOpening.id}/>
        </> :
        <Dimmer active inverted>
          <Loader>{'Cargando búsqueda'}</Loader>
        </Dimmer>
    );
  }
}

const mapStateToProps = state => ({
  jobOpening: state.jobOpeningDetail.entity,
  jobOpeningFetched: state.jobOpeningDetail.fetch.done,
});

const mapDispatchToProps = {
  fetchJobOpeningDetail
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(JobOpeningDetailsPage);
