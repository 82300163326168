import React from 'react';
import {Button, Table} from "semantic-ui-react";
import {Link} from "react-router-dom";

export const AppliedJobsTable = ({appliedJobs}) => (
  <Table compact={'very'} selectable sortable size={'small'}>
    <Table.Header>
      <Table.Row textAlign={'center'}>
        <Table.HeaderCell key={1} content='Título'/>
        <Table.HeaderCell key={2} content='Empresa'/>
        <Table.HeaderCell key={4} content='Notas'/>
        <Table.HeaderCell key={3} content='Acciones'/>
      </Table.Row>
    </Table.Header>
    <Table.Body>
      {
        appliedJobs.map(job =>
          <Table.Row key={job.id} textAlign={'center'}>
            <Table.Cell content={job.jobOpeningTitle} width={3}/>
            <Table.Cell content={job.jobOpeningCompany} width={3}/>
            <Table.Cell content={job.jobOpeningNotes} style={{whiteSpace: 'pre-wrap'}}/>
            <Table.Cell width={2}>
              <Button
                as={Link} to={'/job-openings/' + job.jobOpeningId} target='_blank'
                icon={'user'} circular size={'mini'} color={'instagram'}
              />
              <Button
                icon={'edit'} circular size={'mini'} color={'yellow'} onClick={() => alert('edit clicked')}
              />
              <Button
                icon={'trash'} circular size={'mini'} color={'red'}
              />
            </Table.Cell>
          </Table.Row>
        )
      }
    </Table.Body>
  </Table>
);
