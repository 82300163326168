import React from 'react';
import CandidatesPage from "./pages/candidates/candidates-page";
import LoginPage from "./pages/login/login-page";
import ProtectedRoute from "./pages/protected-route";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import {
  candidateDetailsRoute,
  candidatesRoute,
  clientsRoute,
  homeRoute,
  jobOpeningDetailsRoute,
  jobOpeningsRoute,
  loginRoute, taxesCalculatorRoute
} from "./pages/routes";
import ClientCompaniesPage from "./pages/client-companies/client-companies-page";
import JobOpeningsPage from "./pages/job-openings/job-openings-page";
import CandidateDetails from "./pages/candidate-details/candidate-details-page";
import JobOpeningDetails from "./pages/job-opening-details/job-opening-details-page";
import {TaxesCalculatorPage} from "./pages/taxes-calculator/taxes-calculator-page";

class App extends React.Component {
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route exact path={loginRoute} component={LoginPage}/>
          <ProtectedRoute exact path={homeRoute}>
            <CandidatesPage/>
          </ProtectedRoute>
          <ProtectedRoute exact path={candidatesRoute}>
            <CandidatesPage/>
          </ProtectedRoute>
          <ProtectedRoute exact path={clientsRoute}>
            <ClientCompaniesPage/>
          </ProtectedRoute>
          <ProtectedRoute exact path={jobOpeningsRoute}>
            <JobOpeningsPage/>
          </ProtectedRoute>
          <ProtectedRoute exact path={candidateDetailsRoute}>
            <CandidateDetails/>
          </ProtectedRoute>
          <ProtectedRoute exact path={jobOpeningDetailsRoute}>
            <JobOpeningDetails/>
          </ProtectedRoute>
          <ProtectedRoute exact path={taxesCalculatorRoute}>
            <TaxesCalculatorPage/>
          </ProtectedRoute>
        </Switch>
      </BrowserRouter>
    );
  }
}

export default App;
