import React from 'react';

export class TaxesCalculatorPage extends React.Component {
  render() {
    return <iframe src="https://calculadora-ganancias.tn.com.ar/"
                   title="Calculadora de ganancias"
                   frameBorder="0"
                   height="1533px"
                   width="100%"/>
  }
}
