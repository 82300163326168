import React from 'react';
import {Button, Confirm, Table} from "semantic-ui-react";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {
  closeJobOpeningCandidateDeleteConfirmation,
  openJobOpeningCandidateDeleteConfirmation,
  removeSelectedCandidateFromJobOpening
} from "../actions";

class JobOpeningCandidatesTable extends React.Component {
  render() {
    const {appliedCandidates} = this.props;
    return (
      <Table size={'small'} compact textAlign={'center'} verticalAlign={'middle'} columns={4}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell content={'Nombre'}/>
            <Table.HeaderCell content={'Email'}/>
            <Table.HeaderCell content={'Notas'}/>
            <Table.HeaderCell content={'Estado'}/>
            <Table.HeaderCell content={'Último contacto'}/>
            <Table.HeaderCell content={'Acciones'}/>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {appliedCandidates.map(appliedCandidate => (
            <Table.Row key={appliedCandidate.id}>
              <Table.Cell content={appliedCandidate.candidateFullname} width={2}/>
              <Table.Cell content={appliedCandidate.candidateEmail} width={2}/>
              <Table.Cell content={appliedCandidate.notes}/>
              <Table.Cell content={appliedCandidate.statusName} width={2}/>
              <Table.Cell content={appliedCandidate.lastContactAt} width={2}/>
              <Table.Cell collapsing textAlign={'center'} width={2}>
                <Button
                  target='_blank'
                  as={Link} to={`/candidate/${appliedCandidate.candidateId}`}
                  icon={'user'} circular size={'mini'} color={'instagram'}
                />
                <Button
                  icon={'edit'} circular size={'mini'} color={'yellow'}
                  onClick={() => alert("Edit clicked!")}
                />
                <Button
                  icon={'trash'} circular size={'mini'} color={'red'}
                  onClick={() => this.props.openJobOpeningCandidateDeleteConfirmation(appliedCandidate.id)}
                />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
        <Confirm
          confirmButton={'Remover'}
          cancelButton={'Cancelar'}
          content={'Remover candidato de la búsqueda?'}
          open={this.props.showDeleteConfirmation}
          onConfirm={() => this.props.removeSelectedCandidateFromJobOpening(this.props.currentRemoveId)}
          onCancel={() => this.props.closeJobOpeningCandidateDeleteConfirmation()}
        />
      </Table>
    );
  }
}

const mapStateToProps = state => ({
  currentRemoveId: state.jobOpeningDetail.remove.currentId,
  showDeleteConfirmation: state.jobOpeningDetailUI.showDeleteCandidateConfirmation
});

const mapDispatchToProps = {
  openJobOpeningCandidateDeleteConfirmation,
  closeJobOpeningCandidateDeleteConfirmation,
  removeSelectedCandidateFromJobOpening
};

export default connect(mapStateToProps, mapDispatchToProps)(JobOpeningCandidatesTable);
