import React from 'react';
import {Icon, Message, Modal} from "semantic-ui-react";
import {connect} from "react-redux";
import {addCandidate, closeCandidateCreateModal} from "../actions";
import {CandidateForm} from "../../../components/forms/candidate-form/candidate-form";
import {handleFormChange} from "../../../components/forms/candidate-form/candidate-form-helper";

export class CreateCandidateModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      candidate: {
        technologies: [],
        languages: []
      }
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = handleFormChange.bind(this);
  }

  handleSubmit(event) {
    event.preventDefault();
    this.props.addCandidate(this.state.candidate);
    this.setState({
      candidate: {
        technologies: [],
        languages: []
      }
    })
  }

  render() {
    return (
      <Modal
        open={this.props.showCandidateCreateModal}
        centered closeIcon dimmer={'blurring'}
        onClose={this.props.closeCandidateCreateModal}>
        <Modal.Header>{'Añadir candidato'}</Modal.Header>
        <Modal.Content>

          {/*Create Candidate Form*/}
          <CandidateForm
            onChange={this.handleChange}
            onSubmit={this.handleSubmit}
            candidate={this.state.candidate}/>

          {/* Form status messages */}
          <Message icon hidden={!this.props.isCreatingCandidate} color={'orange'}>
            <Icon name='circle notched' loading/>
            <Message.Content>
              <Message.Header>{'Creando candidato'}</Message.Header>
              {'Solo un momento..'}
            </Message.Content>
          </Message>
          <Message icon hidden={!this.props.isCreated} positive>
            <Message.Content>
              <Message.Header>{'Candidato creado!'}</Message.Header>
              {'Ya podes volver a la tabla de candidatos.'}
            </Message.Content>
          </Message>
          <Message icon hidden={!this.props.creationError} color={'red'}>
            <Message.Content>
              <Message.Header>{'Ocurrio un error al intentar añadir a tu candidato.'}</Message.Header>
              {'Asegurate de que no exista un candidato registrado con ese email.'}
            </Message.Content>
          </Message>

        </Modal.Content>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  showCandidateCreateModal: state.candidatesUI.showCandidateCreateModal,
  isCreatingCandidate: state.candidates.create.pending,
  isCreated: state.candidates.create.done,
  creationError: state.candidates.create.error,
});

const mapDispatchToProps = {
  addCandidate,
  closeCandidateCreateModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateCandidateModal);
