import {
  JOB_OPENING_CLOSE_CREATE_MODAL, JOB_OPENING_CLOSE_DELETE_MODAL,
  JOB_OPENING_CREATE,
  JOB_OPENING_CREATE_DONE,
  JOB_OPENING_CREATE_ERROR,
  JOB_OPENING_CREATE_RESET_STATE,
  JOB_OPENING_DELETE_SUCCESS,
  JOB_OPENING_DELETE_FAILURE,
  JOB_OPENING_PRIORITIES_REQUEST,
  JOB_OPENING_PRIORITIES_REQUEST_DONE,
  JOB_OPENING_PRIORITIES_REQUEST_ERROR,
  JOB_OPENING_REQUEST,
  JOB_OPENING_REQUEST_DONE,
  JOB_OPENING_REQUEST_ERROR,
  JOB_OPENING_OPEN_CREATE_MODAL, JOB_OPENING_SHOW_DELETE_MODAL,
  JOB_OPENING_STATUSES_REQUEST,
  JOB_OPENING_STATUSES_REQUEST_DONE,
  JOB_OPENING_STATUSES_REQUEST_ERROR, JOB_OPENING_DELETE, JOB_OPENING_DELETE_SET_ID
} from "./action-types";
import agent from "../../api_client/api-client";

/********************
 *  Job Openings UI
 ********************/
export const openJobOpeningCreateModal = () => dispatch => {
  dispatch(resetJobOpeningCreateStatus());
  dispatch({type: JOB_OPENING_OPEN_CREATE_MODAL});
};

export const closeJobOpeningCreateModal = () => ({
  type: JOB_OPENING_CLOSE_CREATE_MODAL,
});

export const openJobOpeningDeleteModal = () => ({
  type: JOB_OPENING_SHOW_DELETE_MODAL,
});

export const closeJobOpeningDeleteModal = () => ({
  type: JOB_OPENING_CLOSE_DELETE_MODAL
});

/*************************
 *  Job Openings Entities
 *************************/
export const addJobOpening = jobOpening => dispatch => {
  dispatch(createJobOpening());
  return agent.JobOpenings.create(jobOpening)
    .then(response => {
      dispatch(receiveCreatedJobOpening(response));
    })
    .catch(error => {
      return dispatch(jobOpeningCreationError(error));
    });
};

export const fetchJobOpenings = (offset, limit) => dispatch => {
  dispatch(requestJobOpenings());
  return agent.JobOpenings.list(offset, limit)
    .then(response => {
      dispatch(receiveJobOpenings(response));
    })
    .catch(error =>
      dispatch(jobOpeningsRequestError(error))
    );
};

export const openDeleteModalAndSetCurrentId = jobOpeningId => dispatch => {
  dispatch(openJobOpeningDeleteModal());
  return dispatch(setJobOpeningDeleteId(jobOpeningId));
};

export const deleteJobOpening = (jobOpeningId) => dispatch => {
  dispatch(removeJobOpening());
  return agent.JobOpenings.delete(jobOpeningId)
    .then(_ => {
      dispatch(removeJobOpeningSuccess(jobOpeningId));
      dispatch(closeJobOpeningDeleteModal());
    })
    .catch(error => (jobOpeningDeleteError(error)));
};

// Create
const createJobOpening = () => ({
  type: JOB_OPENING_CREATE,
});

const receiveCreatedJobOpening = (jobOpening) => ({
  type: JOB_OPENING_CREATE_DONE,
  payload: {
    jobOpening
  }
});

const resetJobOpeningCreateStatus = () => ({
  type: JOB_OPENING_CREATE_RESET_STATE,
});

const jobOpeningCreationError = (error) => ({
  type: JOB_OPENING_CREATE_ERROR,
  error: error.toString()
});

// Request
const requestJobOpenings = () => ({
  type: JOB_OPENING_REQUEST,
});

const receiveJobOpenings = (response) => ({
  type: JOB_OPENING_REQUEST_DONE,
  payload: {
    items: response.results,
    count: response.count
  }
});

const jobOpeningsRequestError = (error) => ({
  type: JOB_OPENING_REQUEST_ERROR,
  error: error.toString()
});

//
// Delete
//
const setJobOpeningDeleteId = (jobOpeningId) => ({
  type: JOB_OPENING_DELETE_SET_ID,
  payload: {
    jobOpeningId
  }
});

const removeJobOpening = () => ({
  type: JOB_OPENING_DELETE,
});

const removeJobOpeningSuccess = (jobOpeningId) => ({
  type: JOB_OPENING_DELETE_SUCCESS,
  payload: {
    jobOpeningId: jobOpeningId
  }
});

const jobOpeningDeleteError = error => ({
  type: JOB_OPENING_DELETE_FAILURE,
  error: error
});

/**************************
 *  Job openings statuses
 **************************/
export const fetchJobOpeningsStatus = () => dispatch => {
  dispatch(requestJobOpeningsStatuses());
  return agent.JobOpeningsStatuses.list()
    .then(response => {
      dispatch(receiveJobOpeningsStatuses(response));
    })
    .catch(error =>
      dispatch(jobOpeningsStatusesRequestError(error))
    );
};

const requestJobOpeningsStatuses = () => {
  return {
    type: JOB_OPENING_STATUSES_REQUEST
  }
};

const receiveJobOpeningsStatuses = (response) => {
  return {
    type: JOB_OPENING_STATUSES_REQUEST_DONE,
    payload: {
      items: response
    }
  }
};

const jobOpeningsStatusesRequestError = (error) => {
  return {
    type: JOB_OPENING_STATUSES_REQUEST_ERROR,
    error: error.toString()
  }
};

/****************************
 *  Job openings priorities
 ****************************/
export const fetchJobOpeningsPriorities = () => dispatch => {
  dispatch(requestJobOpeningsPriorities());
  return agent.JobOpeningsPriorities.list()
    .then(response => {
      dispatch(receiveJobOpeningsPriorities(response));
    })
    .catch(error =>
      dispatch(jobOpeningsPrioritiesRequestError(error))
    );
};

const requestJobOpeningsPriorities = () => {
  return {
    type: JOB_OPENING_PRIORITIES_REQUEST
  }
};

const receiveJobOpeningsPriorities = (response) => {
  return {
    type: JOB_OPENING_PRIORITIES_REQUEST_DONE,
    payload: {
      items: response
    }
  }
};

const jobOpeningsPrioritiesRequestError = (error) => {
  return {
    type: JOB_OPENING_PRIORITIES_REQUEST_ERROR,
    error: error
  }
};
