import React from 'react';
import {Dropdown} from "semantic-ui-react";
import {fetchLanguages} from "./actions";
import {connect} from "react-redux";
import {renderLabel} from "../builders";

class LanguagesDropdown extends React.Component {
  componentDidMount() {
    if (!this.props.languages || !this.props.languages.length) {
      this.props.fetchLanguages();
    }
  }

  render() {
    const {name, value, multiple, languages = [], onChange} = this.props;
    return (
      <Dropdown
        selection fluid
        name={name}
        value={value}
        multiple={multiple}
        onChange={onChange}
        loading={!languages}
        options={languages.map(lang => ({
          key: lang.id,
          value: lang.id,
          text: lang.name + ' - ' + lang.level,
        }))}
        renderLabel={renderLabel}
        placeholder={'Idiomas'}
        noResultsMessage={'No se encontraron resultados.'}
      />
    );
  }
}

const mapStateToProps = state => ({
  languages: state.languages.items,
});

const mapDispatchToProps = {
  fetchLanguages,
};

export default connect(mapStateToProps, mapDispatchToProps)(LanguagesDropdown);
