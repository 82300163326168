import React from 'react';
import {connect} from "react-redux";
import {Button, Form, Grid, Icon, Message, Modal} from "semantic-ui-react";
import {associateCandidateToJobOpenings, closeApplyCandidateToJobsModal} from "./actions";
import JobOpeningsSearch from "../job-openings-search/job-openings-search";
import {selectSearchJobOpenings} from "../job-openings-search/actions";
import {fetchJobOpeningCandidateStatus} from "../candidate-status-dropdown/actions";

export class ApplyCandidateToJobsModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      candidateId: undefined,
      jobOpenings: []
    };
    this.handleChange = this.handleChange.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    if (!this.props.candidateStatuses || !this.props.candidateStatuses.length) {
      this.props.fetchJobOpeningCandidateStatus();
    }
  }

  handleChange(event, data) {
    if (['jobOpenings'].includes(data.name)) {
      this.setState({
        [data.name]: data.value
      })
    } else {
      this.setState({
        [event.target.name]: event.target.value
      })
    }
  }

  onSearchChange = (event, data) => {
    this.props.selectSearchJobOpenings(data.value);
  };

  handleJobOpeningStatusChange = (event, data) => {
    // Workaround because dropdown doesn't support sending an object as data.
    // Therefore, we send the object as string and then parse it here.
    const newJobOpening = JSON.parse(data.value);
    this.setState({
      jobOpenings: [
        ...(this.state.jobOpenings.filter(jobOpening => jobOpening.jobOpeningId !== newJobOpening.jobOpeningId)),
        newJobOpening
      ]
    });
  };

  handleSubmit(event) {
    event.preventDefault();
    const {candidateId} = this.props;
    const jobOpenings = this.state.jobOpenings;

    this.props.associateCandidateToJobOpenings(candidateId, jobOpenings);
  };

  render() {
    const {showModal, candidateStatuses, closeApplyCandidateToJobsModal} = this.props;
    return (
      <Modal open={showModal} onClose={closeApplyCandidateToJobsModal}>
        <Modal.Header>{'Aplicar a búsquedas'}</Modal.Header>
        <Modal.Content>
          <Form onSubmit={this.handleSubmit}>
            <Grid>
              <Grid.Row>
                <Grid.Column>
                  <JobOpeningsSearch onChange={this.onSearchChange}/>
                </Grid.Column>
              </Grid.Row>
              {
                this.props.selectedSearchResults.map(jobOpening => (
                  <Grid.Row key={jobOpening.id} verticalAlign={'middle'} textAlign={'left'}
                            style={{paddingTop: '3px', paddingBottom: '3px'}}>
                    <Grid.Column width={6} textAlign={'center'}>
                      <div style={{fontWeight: 600}}>{jobOpening.name + ' - ' + jobOpening.finalCompany}</div>
                    </Grid.Column>
                    <Grid.Column width={6} textAlign={'center'}>
                      <Form.Dropdown
                        selection fluid
                        name={'candidates'}
                        placeholder={'Estado'}
                        loading={!candidateStatuses}
                        onChange={this.handleJobOpeningStatusChange}
                        options={
                          candidateStatuses.map(status => ({
                            key: status.id,
                            value: `{"jobOpeningId": ${jobOpening.id}, "statusId": ${status.id}}`,
                            text: status.name,
                          }))}
                        noResultsMessage={'No se encontraron resultados.'}
                      />
                    </Grid.Column>
                  </Grid.Row>
                ))
              }
              <Grid.Row>
                <Grid.Column width={6}>
                  <Button positive content={'Aplicar candidato a búsquedas'}/>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
          {/*Error*/}
          <Message icon hidden={!this.props.applyingToJobsDone} positive>
            <Message.Content>
              <Message.Header>{'Búsquedas aplicadas!'}</Message.Header>
              {'Ya podes volver a la tabla de candidatos.'}
            </Message.Content>
          </Message>
          {/*Error*/}
          <Message icon hidden={!this.props.applyingToJobs} color={'orange'}>
            <Icon name='circle notched' loading/>
            <Message.Content>
              <Message.Header>{'Aplicando candidato a las búsquedas.'}</Message.Header>
              {'Solo un momento..'}
            </Message.Content>
          </Message>
          {/*Error*/}
          <Message icon hidden={!this.props.applyingToJobsError} negative>
            <Message.Content>
              <Message.Header>{'Ocurrio un error al intentar aplicar el candidato a las búsquedas.'}</Message.Header>
              {'Intenta de nuevo en un momento.'}
            </Message.Content>
          </Message>
        </Modal.Content>
      </Modal>
    )
  }
}

const mapDispatchToProps = {
  closeApplyCandidateToJobsModal,
  selectSearchJobOpenings,
  fetchJobOpeningCandidateStatus,
  associateCandidateToJobOpenings
};

const mapStateToProps = state => ({
  showModal: state.candidateToJobsReducer.showModal,
  candidateId: state.candidateToJobsReducer.currentId,
  applyingToJobs: state.candidateToJobsReducer.pending,
  applyingToJobsDone: state.candidateToJobsReducer.done,
  applyingToJobsError: state.candidateToJobsReducer.error,
  candidateStatuses: state.candidateStatuses.entities,
  selectedSearchResults: state.jobOpeningSearch.selected,
});

export default connect(mapStateToProps, mapDispatchToProps)(ApplyCandidateToJobsModal)
