import agent from "../../api_client/api-client";
import {
  JOB_OPENING_SEARCH,
  JOB_OPENING_SEARCH_FAILURE,
  JOB_OPENING_SEARCH_RESET_STATUS,
  JOB_OPENING_SEARCH_SELECT_JOB_OPENING,
  JOB_OPENING_SEARCH_SUCCESS,
  JOB_OPENING_SEARCH_UNSELECT_JOB_OPENING
} from "./action-types";

const searchJobOpening = query => ({
  type: JOB_OPENING_SEARCH,
  payload: {
    searchQuery: query
  }
});

const searchJobOpeningSuccess = response => ({
  type: JOB_OPENING_SEARCH_SUCCESS,
  payload: {
    jobOpenings: response
  }
});

const searchJobOpeningError = error => ({
  type: JOB_OPENING_SEARCH_FAILURE,
  error: error.toString()
});

export const searchJobOpeningResetStatus = _ => ({
  type: JOB_OPENING_SEARCH_RESET_STATUS,
});

export const searchForJobOpening = (event, data) => dispatch => {
  searchJobOpeningResetStatus();
  if (data['searchQuery']) {
    dispatch(searchJobOpening(data['searchQuery']));
    return agent.JobOpenings.search(encodeURIComponent(data['searchQuery']))
      .then(response => dispatch(searchJobOpeningSuccess(response)))
      .catch(error => dispatch(searchJobOpeningError(error)));
  }
};

export const selectSearchJobOpenings = jobOpenings => ({
  type: JOB_OPENING_SEARCH_SELECT_JOB_OPENING,
  payload: {
    jobOpenings
  }
});

export const unselectSearchJobOpening = jobOpeningId => ({
  type: JOB_OPENING_SEARCH_UNSELECT_JOB_OPENING,
  payload: {
    jobOpeningId
  }
});
