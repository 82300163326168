import agent from "../../api_client/api-client";
import {LANGUAGE_REQUEST, LANGUAGE_REQUEST_DONE, LANGUAGE_REQUEST_ERROR} from "./action-types";

export const fetchLanguages = () => dispatch => {
  dispatch(requestLanguages());
  return agent.Languages.list()
    .then(response => {
      dispatch(receiveRequestedLanguages(response));
    })
    .catch(error =>
      dispatch(requestLanguagesError(error))
    );
};

const requestLanguages = () => {
  return {
    type: LANGUAGE_REQUEST
  };
};

const receiveRequestedLanguages = response => {
  return {
    type: LANGUAGE_REQUEST_DONE,
    payload: {
      items: response,
    }
  };
};

const requestLanguagesError = error => {
  return {
    type: LANGUAGE_REQUEST_ERROR,
    error: error.toString()
  };
};
