import React from 'react';
import {Form, Grid, Modal} from "semantic-ui-react";
import {connect} from "react-redux";
import {associateCandidatesToJobOpening, closeJobOpeningCandidateAddModal} from "../actions";
import CandidatesSearch from "../../../components/candidates-search/candidates-search";
import {fetchJobOpeningCandidateStatus} from "../../../components/candidate-status-dropdown/actions";
import {
  searchForCandidate,
  selectSearchCandidates,
  unselectSearchCandidate
} from "../../../components/candidates-search/actions";

export class AddCandidateModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      candidates: [],
    };
    this.renderLabel = this.renderLabel.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.handleCandidateStatusChange = this.handleCandidateStatusChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    if (!this.props.candidateStatuses || !this.props.candidateStatuses.length) {
      this.props.fetchJobOpeningCandidateStatus();
    }
  }

  renderLabel = (item, index, defaultLabelProps) => {
    // const [name, email] = item.text.split(' - ');
    return {
      color: 'teal',
      content: item.text.split(' - ')[0],
      icon: 'check',
    }
  };

  onSearchChange = (event, data) => {
    this.props.selectSearchCandidates(data.value);
  };

  handleCandidateStatusChange = (event, data) => {
    // Workaround because dropdown doesn't support sending an object as data.
    // Therefore, we send the object as string and then parse it here.
    const newCandidate = JSON.parse(data.value);
    this.setState({
      candidates: [
        ...(this.state.candidates.filter(candidate => candidate.candidateId !== newCandidate.candidateId)),
        newCandidate
      ]
    });
  };

  handleSubmit(event) {
    event.preventDefault();
    const {jobOpeningId} = this.props;
    const candidates = this.state.candidates;

    this.props.associateCandidatesToJobOpening(jobOpeningId, candidates);
  };

  render() {
    return (
      <Modal
        centered
        open={this.props.showModal}
        onClose={this.props.closeJobOpeningCandidateAddModal}
      >
        <Modal.Header>{'Añadir candidato'}</Modal.Header>
        <Modal.Content>
          <Form onSubmit={this.handleSubmit}>
            <Grid columns={16} verticalAlign={'middle'}>
              <Grid.Row>
                <Grid.Column width={16}>
                  <CandidatesSearch
                    onChange={this.onSearchChange}
                    renderLabel={this.renderLabel}/>
                </Grid.Column>
              </Grid.Row>
              {
                this.props.selectedSearchResults.map(candidate => (
                  <Grid.Row key={candidate.id} verticalAlign={'middle'} textAlign={'left'}
                            style={{paddingTop: '3px', paddingBottom: '3px'}}>
                    <Grid.Column width={4}>
                      <div style={{fontWeight: 600}}>{candidate.fullName}</div>
                    </Grid.Column>
                    <Grid.Column width={6}>
                      <div style={{fontWeight: 600}}>{candidate.email}</div>
                    </Grid.Column>
                    <Grid.Column width={6}>
                      <Form.Dropdown
                        selection fluid
                        name={'candidates'}
                        placeholder={'Estado'}
                        loading={!this.props.candidateStatuses}
                        onChange={this.handleCandidateStatusChange}
                        options={
                          this.props.candidateStatuses.map(status => ({
                            key: status.id,
                            value: `{"candidateId": ${candidate.id}, "statusId": ${status.id}}`,
                            text: status.name,
                          }))
                        }
                        noResultsMessage={'No se encontraron resultados.'}
                      />
                    </Grid.Column>
                  </Grid.Row>
                ))
              }
              <Grid.Row>
                <Grid.Column width={16}>
                  <Form.Button
                    positive
                    content={'Añadir candidatos'}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        </Modal.Content>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  showModal: state.jobOpeningDetailUI.showAddCandidateModal,
  selectedSearchResults: state.candidateSearch.selected,
  candidateStatuses: state.candidateStatuses.entities,
});

const mapDispatchToProps = {
  associateCandidatesToJobOpening,
  closeJobOpeningCandidateAddModal,
  fetchJobOpeningCandidateStatus,
  searchForCandidate,
  selectSearchCandidates,
  unselectSearchCandidate
};

export default connect(mapStateToProps, mapDispatchToProps)(AddCandidateModal);
