import React from 'react';
import {loginRoute} from "./routes";
import {Redirect, Route} from "react-router-dom";
import {connect} from "react-redux";
import {ACCESS_TOKEN, getCookie} from "../utils";
import FixedMenuLayout from "./nav-bar";

const ProtectedRoute = ({children, isAuthenticated, ...props}) => (
  <Route
    {...props}
    render={routeProps =>
      isAuthenticated && getCookie(ACCESS_TOKEN) ?
        <>
          <FixedMenuLayout/>
          {React.cloneElement(children, {...routeProps})}
        </> :
        <Redirect
          to={{
            pathname: loginRoute,
            state: {from: routeProps.location}
          }}
        />
    }
  />
);

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, null)(ProtectedRoute);
