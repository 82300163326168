import _ from 'lodash';

export const renderLabel = (label) => ({
  color: 'teal',
  content: label.text,
  icon: 'check',
});

export const buildCandidateForAPI = (props) => ({
  jobTitle: _.toString(props.jobTitle),
  firstName: _.toString(props.firstName),
  lastName: _.toString(props.lastName),
  email: _.toString(props.email),
  phoneNumber: _.toString(props.phoneNumber),
  skypeId: _.toString(props.skypeId),
  linkedinProfileUrl: _.toString(props.linkedinProfileUrl),
  notes: _.toString(props.notes),
  languages: props.languages.map(lang => lang.id),
  technologies: _.isArray(props.technologies) && props.technologies.length && props.yearsOfExperience !== undefined ?
    [{
      id: props.technologies[0].id,
      yearsOfExperience: props.technologies[0].yearsOfExperience
    }] : []
});

/**
 * Generates a Candidate instance so that it can be posted to the API.
 * @param props Candidate with it's attributes
 * @returns Candidate JSON
 */
export const buildCandidate = (props) => {
  return {
    ...(props.jobTitle && {jobTitle: props.jobTitle}),
    ...(props.firstName && {firstName: props.firstName}),
    ...(props.lastName && {lastName: props.lastName}),
    ...(props.email && {email: props.email}),
    ...(props.phoneNumber && {phoneNumber: props.phoneNumber}),
    ...(props.skypeId && {skypeId: props.skypeId}),
    ...(props.linkedinProfileUrl && {linkedinProfileUrl: props.linkedinProfileUrl}),
    ...(props.notes && {notes: props.notes}),
  }
};
