/*
 * Client Companies UI
 */
export const OPEN_CLIENT_COMPANY_CREATE_MODAL = 'OPEN_CLIENT_COMPANY_CREATE_MODAL';
export const CLOSE_CLIENT_COMPANY_CREATE_MODAL = 'CLOSE_CLIENT_COMPANY_CREATE_MODAL';
export const OPEN_CLIENT_COMPANY_DELETE_MODAL = 'OPEN_CLIENT_COMPANY_DELETE_MODAL';
export const CLOSE_CLIENT_COMPANY_DELETE_MODAL = 'CLOSE_CLIENT_COMPANY_DELETE_MODAL';

/*
 * Client companies state
 */
export const CLIENT_COMPANY_CREATE = 'CLIENT_COMPANY_CREATE';
export const CLIENT_COMPANY_CREATE_SUCCESS = 'CLIENT_COMPANY_CREATE_SUCCESS';
export const CLIENT_COMPANY_CREATE_FAILURE = 'CLIENT_COMPANY_CREATE_FAILURE';
export const CLIENT_COMPANY_CREATE_RESET_STATUS = 'CLIENT_COMPANY_CREATE_RESET_STATUS';

export const CLIENT_COMPANY_FETCH = 'CLIENT_COMPANY_FETCH';
export const CLIENT_COMPANY_FETCH_SUCCESS = 'CLIENT_COMPANY_FETCH_SUCCESS';
export const CLIENT_COMPANY_PAGINATED_FETCH_SUCCESS = 'CLIENT_COMPANY_PAGINATED_FETCH_SUCCESS';
export const CLIENT_COMPANY_FETCH_FAILURE = 'CLIENT_COMPANY_FETCH_FAILURE';

export const CLIENT_COMPANY_UPDATE = 'CLIENT_COMPANY_UPDATE';

export const CLIENT_COMPANY_DELETE = 'CLIENT_COMPANY_DELETE';
export const CLIENT_COMPANY_DELETE_SUCCESS = 'CLIENT_COMPANY_DELETE_SUCCESS';
export const CLIENT_COMPANY_DELETE_FAILURE = 'CLIENT_COMPANY_DELETE_FAILURE';
export const CLIENT_COMPANY_DELETE_SET_ID = 'CLIENT_COMPANY_DELETE_SET_ID';
export const CLIENT_COMPANY_DELETE_RESET_STATUS = 'CLIENT_COMPANY_DELETE_RESET_STATUS';
