import React from 'react';
import {Button, Table} from "semantic-ui-react";
import {connect} from "react-redux";
import {modifyCandidate, openCandidateDeleteModalAndUpdateCurrentId} from "../actions";
import CreateCandidateModal from "./create-candidate-modal";
import CandidateDeleteModal from "./delete-candidate-modal";
import {Link} from "react-router-dom";
import ApplyCandidateToJobsModal from "../../../components/apply-candidate-to-jobs-modal/apply-candidate-to-jobs-modal";
import {showApplyCandidateToJobsModal} from "../../../components/apply-candidate-to-jobs-modal/actions";

class CandidatesTable extends React.Component {
  state = {
    showModal: false
  };

  headers = [
    'Acciones',
    'Último contacto',
    'Título',
    'Nombre',
    'Email',
    'Teléfono',
    'Tecnología Principal',
    'Perfil de LinkedIn',
    'Otras tecnologías',
    'Idiomas',
  ];

  render() {
    const {candidates} = this.props;
    return (
      <>
        <Table compact={'very'} selectable sortable size={'small'}>
          <Table.Header>
            <Table.Row>
              {
                this.headers.map((header) =>
                  <Table.HeaderCell key={header} textAlign={'center'}>
                    {header}
                  </Table.HeaderCell>)
              }
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {
              candidates && candidates.map(candidate => {
                return (
                  <Table.Row key={candidate.id}>
                    <Table.Cell collapsing textAlign={'center'}>
                      <Button
                        icon={'trash'} circular size={'mini'} color={'red'}
                        onClick={() => this.props.openCandidateDeleteModalAndUpdateCurrentId(candidate.id)}
                      />
                      <Button
                        as={Link} to={`/candidate/${candidate.id}`} target='_blank'
                        icon={'user'} circular size={'mini'} color={'instagram'}
                      />
                      <Button
                        icon={'add'} circular size={'mini'} color={'green'}
                        onClick={() => this.props.showApplyCandidateToJobsModal(candidate.id)}
                      />
                    </Table.Cell>
                    <Table.Cell collapsing textAlign={'center'}>{candidate.lastContactAt}</Table.Cell>
                    <Table.Cell collapsing textAlign={'center'}>{candidate.jobTitle}</Table.Cell>
                    <Table.Cell collapsing textAlign={'center'}>{candidate.fullName}</Table.Cell>
                    <Table.Cell collapsing textAlign={'center'}>{candidate.email}</Table.Cell>
                    <Table.Cell collapsing textAlign={'center'}>{candidate.phoneNumber}</Table.Cell>
                    <Table.Cell collapsing textAlign={'center'}>
                      {candidate.mainTechnology && candidate.mainTechnology.name ? candidate.mainTechnology.name : ''}
                    </Table.Cell>
                    <Table.Cell collapsing textAlign={'center'}>{candidate.linkedinProfileUrl}</Table.Cell>
                    <Table.Cell collapsing textAlign={'center'}>
                      {
                        candidate.otherTechnologies.map(tech => tech.name).join(', ')
                      }
                    </Table.Cell>
                    <Table.Cell collapsing textAlign={'center'}>
                      {candidate.languages && candidate.languages.join(', ')}
                    </Table.Cell>
                  </Table.Row>
                );
              })
            }
          </Table.Body>
        </Table>
        <CreateCandidateModal/>
        <CandidateDeleteModal/>
        <ApplyCandidateToJobsModal/>
      </>
    );
  }
}

const mapDispatchToProps = {
  modifyCandidate,
  showApplyCandidateToJobsModal,
  openCandidateDeleteModalAndUpdateCurrentId
};

export default connect(null, mapDispatchToProps)(CandidatesTable);
