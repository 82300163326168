import axios from "axios";
import {logOutUser} from "../redux/actions/auth-actions";
import {ACCESS_TOKEN, eraseCookie, REFRESH_TOKEN} from "../utils";

export default {
  setupInterceptors: (store, history) => {
    axios.interceptors.response.use(
      response => response,
      error => {
        if (error.response && error.response.status === 401) {
          eraseCookie(ACCESS_TOKEN);
          eraseCookie(REFRESH_TOKEN);
          store.dispatch(logOutUser());
        }
        return Promise.reject(error)
      }
    );
  }
}
