import React from 'react';
import {Container, Grid, Header, Icon, Label} from "semantic-ui-react";
import {Link} from "react-router-dom";
import ProfileActionButtons from "./profile-action-buttons";
import {AppliedJobsTable} from "./applied-jobs-table";
import ApplyCandidateToJobsModal from "../../../components/apply-candidate-to-jobs-modal/apply-candidate-to-jobs-modal";
import {formatCandidate} from "../../candidates/actions";

class Profile extends React.Component {
  render() {
    const formattedCandidate = formatCandidate(this.props.candidate);
    return (
      <Container>
        <Grid columns={16} style={{
          padding: '1em',
          marginTop: '1em',
          border: '1px solid #d4d4d5',
          borderRadius: '1px',
          background: '#fafafa',
        }}>
          <Grid.Column width={13}>
            <Grid columns={12}>
              <TitleRow candidate={formattedCandidate}/>
              <ContactInfoRow candidate={formattedCandidate}/>
              <LanguagesRow candidate={formattedCandidate}/>
              <TechnologiesRow candidate={formattedCandidate}/>
              <NotesRow candidate={formattedCandidate}/>
              <FilesRow/>
              <AppliedJobOpeningsRow appliedJobs={formattedCandidate.appliedJobs}/>
            </Grid>
          </Grid.Column>
          <Grid.Column width={3}>
            <ProfileActionButtons/>
          </Grid.Column>
        </Grid>
        <ApplyCandidateToJobsModal/>
      </Container>
    );
  }
}

const TitleRow = ({candidate: {fullName, jobTitle}}) => (
  <Grid.Row>
    <Grid.Column width={16}>
      <Header textAlign='center' size={'large'}>
        <Header.Content content={fullName}/>
        <Header.Subheader content={jobTitle}/>
      </Header>
    </Grid.Column>
  </Grid.Row>
);

const ContactInfoRow = ({candidate: {email, linkedinProfileUrl, skypeId, phoneNumber, lastContactAt}}) => {
  return (
    <Grid.Row>
      <Grid.Column width={16}>
        <Header content={'Datos personales'}/>
      </Grid.Column>
      <Grid.Column width={16}>
        <b>{'Email: '}</b>
        <a href={`mailto:${email}`}>
          {email}
        </a>
      </Grid.Column>
      <Grid.Column width={16}>
        <b>{'LinkedIn: '}</b>
        <Link to={linkedinProfileUrl}>
          {linkedinProfileUrl}
        </Link>
      </Grid.Column>
      <Grid.Column width={16}>
        <b>{'Id de Skype: '}</b>
        {skypeId}
      </Grid.Column>
      <Grid.Column width={16}>
        <b>{'Teléfono: '}</b>
        {phoneNumber}
      </Grid.Column>
      <Grid.Column width={16}>
        <b>{'Último contacto: '}</b>
        {lastContactAt}
      </Grid.Column>
    </Grid.Row>
  )
};

const LanguagesRow = ({candidate: {languages}}) => (
  <Grid.Column width={16}>
    <Header>{'Idiomas'}</Header>
    {languages.length !== 0 && languages.map(language => (<Label key={language} content={language} color={'teal'}/>))}
  </Grid.Column>
);

const TechnologiesRow = ({candidate: {mainTechnology, otherTechnologies}}) => (
  <>
    <Grid.Row style={{paddingBottom: 0}}>
      <Grid.Column width={16}>
        <Header content={'Tecnologías'}/>
      </Grid.Column>
    </Grid.Row>
    <Grid.Row style={{paddingBottom: 0}}>
      <Grid.Column width={16}>
        <Header size={'small'}>{'Tecnología principal'}</Header>
        {mainTechnology && <Label content={mainTechnology.name} color={'teal'}/>}
      </Grid.Column>
    </Grid.Row>
    <Grid.Row style={{paddingBottom: 0}}>
      <Grid.Column width={16}>
        <Header size={'small'} content={'Otras tecnologías'}/>
        {
          otherTechnologies.map(tech =>
            <Label key={tech.id}
                   content={tech.name}
                   color={'teal'}
                   style={{marginBottom: '.5em'}}/>)
        }
      </Grid.Column>
    </Grid.Row>
  </>
);

const NotesRow = ({candidate: {notes}}) => (
  <Grid.Row>
    <Grid.Column width={16}>
      <Header content={'Notas generales'}/>
      <p>{notes}</p>
    </Grid.Column>
  </Grid.Row>
);

const FilesRow = _ => (
  <Grid.Row>
    <Grid.Column width={16}>
      <Header content={'Archivos'}/>
      <Icon name={'file alternate outline'} size={'large'}/>
      <Icon name={'file alternate outline'} size={'large'}/>
      <Icon name={'file alternate outline'} size={'large'}/>
    </Grid.Column>
  </Grid.Row>
);

const AppliedJobOpeningsRow = ({appliedJobs}) => (
  <Grid.Row>
    <Grid.Column width={16}>
      <Header content={'Búsquedas aplicadas'}/>
      <AppliedJobsTable appliedJobs={appliedJobs}/>
    </Grid.Column>
  </Grid.Row>
);

export default Profile;
