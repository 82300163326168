import {Grid} from "semantic-ui-react";
import React from "react";
import CandidatesSearch from "../../../../../components/candidates-search/candidates-search";


export const CandidatesRow = ({onChange}) => (
  <Grid.Row columns={1}>
    <Grid.Column>
      <div className={'label'}>
        {'Candidatos'}
      </div>
      <CandidatesSearch onChange={onChange}/>
    </Grid.Column>
  </Grid.Row>
);

