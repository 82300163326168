import {LANGUAGE_REQUEST, LANGUAGE_REQUEST_DONE, LANGUAGE_REQUEST_ERROR} from "./action-types";

const initialState = {
  items: [],
  isFetching: false,
  fetchError: false,
};

export const languagesReducer = (state = initialState, action) => {
  switch (action.type) {
    case LANGUAGE_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case LANGUAGE_REQUEST_DONE:
      return {
        ...state,
        isFetching: false,
        items: action.payload.items,
      };
    case LANGUAGE_REQUEST_ERROR:
      return {
        ...state,
        fetchError: true,
      };
    default:
      return state;
  }
};
