/*************
 *  Entities
 *************/
export const JOB_OPENING_DETAIL_FETCH = 'JOB_OPENING_DETAIL_FETCH';
export const JOB_OPENING_DETAIL_FETCH_SUCCESS = 'JOB_OPENING_DETAIL_FETCH_SUCCESS';
export const JOB_OPENING_DETAIL_FETCH_FAILURE = 'JOB_OPENING_DETAIL_FETCH_FAILURE';
export const JOB_OPENING_DETAIL_FETCH_RESET_STATUS = 'JOB_OPENING_DETAIL_FETCH_RESET_STATUS';
export const JOB_OPENING_DETAIL_FETCH_SET_ID = 'JOB_OPENING_DETAIL_FETCH_SET_ID';

export const JOB_OPENING_CANDIDATE_ADD = 'JOB_OPENING_CANDIDATE_ADD';
export const JOB_OPENING_CANDIDATE_ADD_SUCCESS = 'JOB_OPENING_CANDIDATE_ADD_SUCCESS';
export const JOB_OPENING_CANDIDATE_ADD_FAILURE = 'JOB_OPENING_CANDIDATE_ADD_FAILURE';
export const JOB_OPENING_CANDIDATE_DELETE = 'JOB_OPENING_CANDIDATE_DELETE';
export const JOB_OPENING_CANDIDATE_DELETE_SET_ID = 'JOB_OPENING_CANDIDATE_DELETE_SET_ID';
export const JOB_OPENING_CANDIDATE_DELETE_SUCCESS = 'JOB_OPENING_CANDIDATE_DELETE_SUCCESS';
export const JOB_OPENING_CANDIDATE_DELETE_FAILURE = 'JOB_OPENING_CANDIDATE_DELETE_FAILURE';


/******
 * UI
 ******/
export const OPEN_JOB_OPENING_CANDIDATE_ADD_MODAL = 'OPEN_JOB_OPENING_CANDIDATE_ADD_MODAL';
export const CLOSE_JOB_OPENING_CANDIDATE_ADD_MODAL = 'CLOSE_JOB_OPENING_CANDIDATE_ADD_MODAL';
export const OPEN_JOB_OPENING_CANDIDATE_DELETE_CONFIRMATION = 'OPEN_JOB_OPENING_CANDIDATE_DELETE_CONFIRMATION';
export const CLOSE_JOB_OPENING_CANDIDATE_DELETE_CONFIRMATION = 'CLOSE_JOB_OPENING_CANDIDATE_DELETE_CONFIRMATION ';
