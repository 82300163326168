import {
  CLIENT_COMPANY_FETCH_FAILURE,
  CLOSE_CLIENT_COMPANY_DELETE_MODAL,
  CLIENT_COMPANY_CREATE,
  CLIENT_COMPANY_CREATE_FAILURE,
  CLIENT_COMPANY_CREATE_RESET_STATUS,
  CLIENT_COMPANY_CREATE_SUCCESS,
  CLIENT_COMPANY_DELETE,
  CLIENT_COMPANY_DELETE_FAILURE,
  CLIENT_COMPANY_DELETE_RESET_STATUS,
  CLIENT_COMPANY_DELETE_SET_ID,
  CLIENT_COMPANY_DELETE_SUCCESS,
  OPEN_CLIENT_COMPANY_DELETE_MODAL,
  CLIENT_COMPANY_FETCH_SUCCESS,
  CLIENT_COMPANY_PAGINATED_FETCH_SUCCESS,
  CLIENT_COMPANY_FETCH, OPEN_CLIENT_COMPANY_CREATE_MODAL, CLOSE_CLIENT_COMPANY_CREATE_MODAL
} from "./action-types";

export const clientCompaniesReducerInitialState = {
  entities: [],
  paginatedEntities: [],
  paginatedEntitiesCount: 0,
  create: {
    pending: false,
    done: false,
    error: false
  },
  fetch: {
    pending: false,
    done: false,
    error: false
  },
  delete: {
    currentId: undefined,
    pending: false,
    done: false,
    error: false
  }
};

/***************************
 *  clientCompaniesReducer
 ***************************/
export const clientCompaniesReducer = (state = clientCompaniesReducerInitialState, action) => {
  switch (action.type) {
    // Create
    case CLIENT_COMPANY_CREATE:
      return {
        ...state,
        create: {
          ...state.create,
          pending: true
        }
      };
    case CLIENT_COMPANY_CREATE_SUCCESS:
      return {
        ...state,
        entities: [
          ...state.entities,
          {
            id: action.payload.clientCompany.id,
            name: action.payload.clientCompany.name
          }
        ],
        paginatedEntities: [
          ...state.paginatedEntities,
          action.payload.clientCompany
        ],
        paginatedEntitiesCount: ++state.paginatedEntities,
        create: {
          ...state.create,
          pending: false,
          done: true
        },
      };
    case CLIENT_COMPANY_CREATE_FAILURE:
      return {
        ...state,
        create: {
          ...state.create,
          pending: false,
          error: true
        }
      };
    case CLIENT_COMPANY_CREATE_RESET_STATUS:
      return {
        ...state,
        create: {
          ...clientCompaniesReducerInitialState.create
        }
      };
    // Request
    case CLIENT_COMPANY_FETCH:
      return {
        ...state,
        fetch: {
          ...state.fetch,
          pending: true
        },
      };
    case CLIENT_COMPANY_FETCH_SUCCESS:
      return {
        ...state,
        entities: action.payload.clientCompanies,
        fetch: {
          ...state.fetch,
          pending: false,
          done: true
        }
      };
    case CLIENT_COMPANY_PAGINATED_FETCH_SUCCESS:
      return {
        ...state,
        paginatedEntities: action.payload.clientCompanies,
        paginatedEntitiesCount: action.payload.clientCompaniesCount,
        fetch: {
          ...state.fetch,
          pending: false,
          done: true
        },
      };
    case CLIENT_COMPANY_FETCH_FAILURE:
      return {
        ...state,
        fetch: {
          ...state.fetch,
          error: true
        },
      };
    // Delete
    case CLIENT_COMPANY_DELETE:
      return {
        ...state,
        delete: {
          ...state.delete,
          pending: true
        }
      };
    case CLIENT_COMPANY_DELETE_SUCCESS:
      return {
        ...state,
        entities: state.entities.filter(company => company.id !== action.payload.clientCompanyId),
        paginatedEntities: state.paginatedEntities.filter(company => company.id !== action.payload.clientCompanyId),
        // TODO: Update paginatedEntitiesCount if the company was deleted
        delete: {
          ...state.delete,
          pending: false,
          done: true
        }
      };
    case CLIENT_COMPANY_DELETE_FAILURE:
      return {
        ...state,
        delete: {
          ...state.delete,
          error: true,
          pending: false
        }
      };
    case CLIENT_COMPANY_DELETE_SET_ID:
      return {
        ...state,
        delete: {
          ...state.delete,
          currentId: action.payload.clientCompanyId
        }
      };
    case CLIENT_COMPANY_DELETE_RESET_STATUS:
      return {
        ...state,
        delete: {
          ...clientCompaniesReducerInitialState.delete
        }
      };
    default:
      return state;
  }
};

/*****************************
 *  clientCompaniesUIReducer
 *****************************/
export const clientCompaniesUIReducerInitialState = {
  showClientCompanyCreateModal: false,
  showClientCompanyDeleteModal: false
};

export const clientCompaniesUIReducer = (state = clientCompaniesUIReducerInitialState, action) => {
  switch (action.type) {
    case OPEN_CLIENT_COMPANY_CREATE_MODAL:
      return {
        ...state,
        showClientCompanyCreateModal: true
      };
    case CLOSE_CLIENT_COMPANY_CREATE_MODAL:
      return {
        ...state,
        showClientCompanyCreateModal: false
      };
    case OPEN_CLIENT_COMPANY_DELETE_MODAL:
      return {
        ...state,
        showClientCompanyDeleteModal: true
      };
    case CLOSE_CLIENT_COMPANY_DELETE_MODAL:
      return {
        ...state,
        showClientCompanyDeleteModal: false
      };
    default:
      return state;
  }
};
