import agent from "../../api_client/api-client";
import {
  CANDIDATE_SEARCH,
  CANDIDATE_SEARCH_FAILURE,
  CANDIDATE_SEARCH_RESET_STATUS,
  CANDIDATE_SEARCH_SELECT_CANDIDATE,
  CANDIDATE_SEARCH_SUCCESS,
  CANDIDATE_SEARCH_UNSELECT_CANDIDATE
} from "./action-types";

const searchCandidate = query => ({
  type: CANDIDATE_SEARCH,
  payload: {
    searchQuery: query
  }
});

const searchCandidateSuccess = response => ({
  type: CANDIDATE_SEARCH_SUCCESS,
  payload: {
    candidates: response
  }
});

const searchCandidateError = error => ({
  type: CANDIDATE_SEARCH_FAILURE,
  error: error.toString()
});

export const searchCandidateResetStatus = _ => ({
  type: CANDIDATE_SEARCH_RESET_STATUS,
});

export const searchForCandidate = (event, data) => dispatch => {
  if (data['searchQuery']) {
    dispatch(searchCandidate(data['searchQuery']));
    return agent.Candidates.search(data['searchQuery'])
      .then(response => dispatch(searchCandidateSuccess(response)))
      .catch(error => dispatch(searchCandidateError(error)));
  }
};

export const selectSearchCandidates = candidates => ({
  type: CANDIDATE_SEARCH_SELECT_CANDIDATE,
  payload: {
    candidates: candidates
  }
});

export const unselectSearchCandidate = candidateId => ({
  type: CANDIDATE_SEARCH_UNSELECT_CANDIDATE,
  payload: {
    candidateId
  }
});
