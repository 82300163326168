import {
  JOB_OPENING_CANDIDATE_STATUS_FETCH,
  JOB_OPENING_CANDIDATE_STATUS_FETCH_FAILURE,
  JOB_OPENING_CANDIDATE_STATUS_FETCH_SUCCESS
} from "./action-types";

const initialState = {
  entities: [],
  fetch: {
    done: false,
    pending: false,
    error: undefined
  },
};

export const candidateStatusesReducer = (state = initialState, action) => {
  switch (action.type) {
    case JOB_OPENING_CANDIDATE_STATUS_FETCH:
      return {
        ...state,
        fetch: {
          ...initialState.fetch,
          pending: true
        }
      };
    case JOB_OPENING_CANDIDATE_STATUS_FETCH_SUCCESS:
      return {
        ...state,
        entities: action.payload.candidateStatuses,
        fetch: {
          ...initialState.fetch,
          done: true
        }
      };
    case JOB_OPENING_CANDIDATE_STATUS_FETCH_FAILURE:
      return {
        ...state,
        fetch: {
          ...initialState.fetch,
          error: action.error
        }
      };
    default:
      return state;
  }
};
