import React from 'react';
import {Dropdown} from "semantic-ui-react";
import {connect} from "react-redux";
import {searchForCandidate} from "./actions";

export class CandidatesSearch extends React.Component {
  renderLabel = (item, index, defaultLabelProps) => {
    return {
      color: 'teal',
      content: item.text,
      icon: 'check',
    }
  };

  render() {
    const {searchForCandidate, searchResults, isSearching, onChange, renderLabel} = this.props;
    return (
      <Dropdown
        multiple selection search fluid
        name={'candidates'}
        placeholder={'Candidatos'}
        onChange={onChange}
        onSearchChange={searchForCandidate}
        options={searchResults.map(candidate => ({
          key: candidate.id,
          value: candidate.id,
          text: candidate.fullName + ' - ' + candidate.email,
        }))}
        loading={isSearching}
        renderLabel={renderLabel !== undefined ? renderLabel : this.renderLabel}
        noResultsMessage={'No se encontraron resultados.'}
      />
    )
  }
}

const mapStateToProps = state => ({
  isSearching: state.candidateSearch.pending,
  searchResults: state.candidateSearch.results,
});

const mapDispatchToProps = {
  searchForCandidate
};

export default connect(mapStateToProps, mapDispatchToProps)(CandidatesSearch)
