// Technology Reducer
export const TECHNOLOGY_CREATE = 'TECHNOLOGY_CREATE';
export const TECHNOLOGY_CREATE_DONE = 'TECHNOLOGY_CREATE_DONE';
export const TECHNOLOGY_CREATE_RESET_STATE = 'TECHNOLOGY_CREATE_RESET_STATE';
export const TECHNOLOGY_CREATE_ERROR = 'TECHNOLOGY_CREATE_ERROR';

export const TECHNOLOGY_REQUEST = 'TECHNOLOGY_REQUEST';
export const TECHNOLOGY_REQUEST_DONE = 'TECHNOLOGY_REQUEST_DONE';
export const TECHNOLOGY_REQUEST_ERROR = 'TECHNOLOGY_REQUEST_ERROR';



// Technology Reducer UI
export const TECHNOLOGY_CLOSE_CREATE_MODAL = 'TECHNOLOGY_CLOSE_CREATE_MODAL';
