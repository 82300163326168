import agent from "../../api_client/api-client";
import {
  CLOSE_CLIENT_COMPANY_DELETE_MODAL,
  CLIENT_COMPANY_CREATE,
  CLIENT_COMPANY_CREATE_FAILURE,
  CLIENT_COMPANY_CREATE_RESET_STATUS,
  CLIENT_COMPANY_CREATE_SUCCESS,
  CLIENT_COMPANY_DELETE,
  CLIENT_COMPANY_DELETE_FAILURE,
  CLIENT_COMPANY_DELETE_RESET_STATUS,
  CLIENT_COMPANY_DELETE_SET_ID,
  CLIENT_COMPANY_DELETE_SUCCESS,
  CLIENT_COMPANY_FETCH,
  CLIENT_COMPANY_FETCH_FAILURE,
  CLIENT_COMPANY_FETCH_SUCCESS,
  OPEN_CLIENT_COMPANY_DELETE_MODAL,
  CLIENT_COMPANY_PAGINATED_FETCH_SUCCESS,
  CLOSE_CLIENT_COMPANY_CREATE_MODAL,
  OPEN_CLIENT_COMPANY_CREATE_MODAL
} from "./action-types";

/********
 *  UI  *
 ********/
export const openClientCompanyCreateModal = () => ({
  type: OPEN_CLIENT_COMPANY_CREATE_MODAL
});

export const closeClientCompanyCreateModal = () => ({
  type: CLOSE_CLIENT_COMPANY_CREATE_MODAL
});

export const openClientCompanyDeleteModal = () => ({
  type: OPEN_CLIENT_COMPANY_DELETE_MODAL
});

export const closeClientCompanyDeleteModal = () => ({
  type: CLOSE_CLIENT_COMPANY_DELETE_MODAL
});

/*
 * clientCompaniesReducer
 */
const createClientCompany = _ => ({
  type: CLIENT_COMPANY_CREATE,
});

const clientCompanyCreated = (clientCompany) => ({
  type: CLIENT_COMPANY_CREATE_SUCCESS,
  payload: {
    clientCompany: clientCompany
  }
});

const clientCreationError = _ => ({
  type: CLIENT_COMPANY_CREATE_FAILURE,
});

const resetClientCompanyCreateStatus = () => ({
  type: CLIENT_COMPANY_CREATE_RESET_STATUS
});

const requestClientCompanies = _ => ({
  type: CLIENT_COMPANY_FETCH,
});

const receivePaginatedClientCompanies = (response) => ({
  type: CLIENT_COMPANY_PAGINATED_FETCH_SUCCESS,
  payload: {
    clientCompanies: response.results,
    clientCompaniesCount: response.count
  }
});

const receiveClientCompanies = (response) => ({
  type: CLIENT_COMPANY_FETCH_SUCCESS,
  payload: {
    clientCompanies: response
  }
});

const fetchClientCompaniesError = (error) => ({
  type: CLIENT_COMPANY_FETCH_FAILURE,
  error: error.toString()
});

const removeClientCompany = () => ({
  type: CLIENT_COMPANY_DELETE
});

const clientCompanyRemoveSuccess = (clientCompanyId) => ({
  type: CLIENT_COMPANY_DELETE_SUCCESS,
  payload: {
    clientCompanyId
  }
});

const clientCompanyRemoveError = (error) => ({
  type: CLIENT_COMPANY_DELETE_FAILURE,
  error: error.toString()
});

const setClientCompanyDeleteId = (clientCompanyId) => ({
  type: CLIENT_COMPANY_DELETE_SET_ID,
  payload: {
    clientCompanyId
  }
});

const resetClientCompanyDeleteStatus = () => ({
  type: CLIENT_COMPANY_DELETE_RESET_STATUS,
});

export const addClientCompany = clientCompany => dispatch => {
  dispatch(resetClientCompanyCreateStatus());
  dispatch(createClientCompany());
  return agent.ClientCompanies.create(clientCompany)
    .then(
      response => {
        dispatch(clientCompanyCreated(response));
        setTimeout(() => {
          dispatch(closeClientCompanyCreateModal());
        }, 3000)
      },
    )
    .catch(
      error => dispatch(clientCreationError(error))
    );
};

export const fetchClientCompaniesPaginated = (offset, limit) => dispatch => {
  dispatch(requestClientCompanies());
  return agent.ClientCompanies.listPaginated(offset, limit)
    .then(response => dispatch(receivePaginatedClientCompanies(response)))
    .catch(error => dispatch(fetchClientCompaniesError(error)));
};

export const fetchClientCompanies = () => dispatch => {
  dispatch(requestClientCompanies());
  return agent.ClientCompanies.list()
    .then(response => dispatch(receiveClientCompanies(response)))
    .catch(error => dispatch(fetchClientCompaniesError(error)));
};

export const deleteClientCompany = (clientCompanyId) => dispatch => {
  dispatch(removeClientCompany());
  return agent.ClientCompanies.delete(clientCompanyId)
    .then(_ => {
      dispatch(clientCompanyRemoveSuccess(clientCompanyId));
      dispatch(resetClientCompanyDeleteStatus());
      dispatch(closeClientCompanyDeleteModal());
    })
    .catch(error => dispatch(clientCompanyRemoveError(error)));
};

export const openClientCompanyDeleteModalAndSetCurrentId = clientCompanyId => dispatch =>
  Promise.resolve(
    dispatch(setClientCompanyDeleteId(clientCompanyId)))
    .then(dispatch(openClientCompanyDeleteModal()));
