import {LOGIN_USER, LOGOUT_USER} from "../action-types";

export const loginUser = () => {
  return {
    type: LOGIN_USER
  }
};
export const logOutUser = () => {
  return {
    type: LOGOUT_USER
  }
};
