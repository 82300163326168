import {
  CLOSE_JOB_OPENING_CANDIDATE_ADD_MODAL,
  CLOSE_JOB_OPENING_CANDIDATE_DELETE_CONFIRMATION,
  JOB_OPENING_CANDIDATE_ADD,
  JOB_OPENING_CANDIDATE_ADD_FAILURE,
  JOB_OPENING_CANDIDATE_ADD_SUCCESS,
  JOB_OPENING_CANDIDATE_DELETE,
  JOB_OPENING_CANDIDATE_DELETE_FAILURE,
  JOB_OPENING_CANDIDATE_DELETE_SET_ID,
  JOB_OPENING_CANDIDATE_DELETE_SUCCESS,
  JOB_OPENING_DETAIL_FETCH,
  JOB_OPENING_DETAIL_FETCH_FAILURE,
  JOB_OPENING_DETAIL_FETCH_RESET_STATUS,
  JOB_OPENING_DETAIL_FETCH_SUCCESS,
  OPEN_JOB_OPENING_CANDIDATE_ADD_MODAL,
  OPEN_JOB_OPENING_CANDIDATE_DELETE_CONFIRMATION
} from "./action-types";
import agent from "../../api_client/api-client";
import {searchCandidateResetStatus} from "../../components/candidates-search/actions";

const fetchJobOpening = () => ({
  type: JOB_OPENING_DETAIL_FETCH
});

const fetchJobOpeningSuccess = jobOpening => ({
  type: JOB_OPENING_DETAIL_FETCH_SUCCESS,
  payload: {
    jobOpening: jobOpening
  }
});

const fetchJobOpeningFailure = () => ({
  type: JOB_OPENING_DETAIL_FETCH_FAILURE
});

const resetJobOpeningFetchStatus = () => ({
  type: JOB_OPENING_DETAIL_FETCH_RESET_STATUS
});

const addCandidatesToJobOpening = _ => ({
  type: JOB_OPENING_CANDIDATE_ADD
});

const addCandidatesToJobOpeningSuccess = (jobOpeningId, response) => ({
  type: JOB_OPENING_CANDIDATE_ADD_SUCCESS,
  payload: {
    jobOpeningId,
    candidates: response
  }
});

const addCandidatesToJobOpeningFailure = error => ({
  type: JOB_OPENING_CANDIDATE_ADD_FAILURE,
  error: error.toString()
});

const setCurrentRemoveCandidateId = id => ({
  type: JOB_OPENING_CANDIDATE_DELETE_SET_ID,
  payload: {
    relationId: id
  }
});

const removeCandidateFromJobOpeningRequest = () => ({
  type: JOB_OPENING_CANDIDATE_DELETE
});

const removeCandidateFromJobOpeningSuccess = (id, response) => ({
  type: JOB_OPENING_CANDIDATE_DELETE_SUCCESS,
  payload: {
    deletedCandidateId: id
  }
});

const removeCandidateFromJobOpeningFailure = (error) => ({
  type: JOB_OPENING_CANDIDATE_DELETE_FAILURE
});

export const fetchJobOpeningDetail = jobOpeningId => dispatch => {
  dispatch(resetJobOpeningFetchStatus());
  dispatch(fetchJobOpening());
  return agent.JobOpenings.detail(jobOpeningId)
    .then(response => dispatch(fetchJobOpeningSuccess(response)))
    .catch(error => dispatch(fetchJobOpeningFailure(error)));
};

export const removeSelectedCandidateFromJobOpening = (id) => dispatch => {
  dispatch(removeCandidateFromJobOpeningRequest());
  agent.JobOpeningCandidates.remove(id)
    .then(response => {
      dispatch(closeJobOpeningCandidateDeleteConfirmation());
      return dispatch(removeCandidateFromJobOpeningSuccess(id, response))
    })
    .catch(error => dispatch(removeCandidateFromJobOpeningFailure(error)))
};

export const associateCandidatesToJobOpening = (jobOpeningId, candidates) => dispatch => {
  dispatch(addCandidatesToJobOpening());
  return agent.JobOpenings.addCandidates(jobOpeningId, candidates)
    .then(response => {
      dispatch(closeJobOpeningCandidateAddModal());
      return dispatch(addCandidatesToJobOpeningSuccess(jobOpeningId, response));
    })
    .catch(error => dispatch(addCandidatesToJobOpeningFailure(error)));
};

/********
 *  UI
 ********/
export const openJobOpeningCandidateDeleteConfirmation = id => dispatch => {
  dispatch(setCurrentRemoveCandidateId(id));
  dispatch({type: OPEN_JOB_OPENING_CANDIDATE_DELETE_CONFIRMATION});
};

export const closeJobOpeningCandidateDeleteConfirmation = () => ({
  type: CLOSE_JOB_OPENING_CANDIDATE_DELETE_CONFIRMATION
});

export const openJobOpeningCandidateAddModal = () => dispatch => {
  dispatch(searchCandidateResetStatus());
  dispatch({type: OPEN_JOB_OPENING_CANDIDATE_ADD_MODAL})
};

export const closeJobOpeningCandidateAddModal = () => ({
  type: CLOSE_JOB_OPENING_CANDIDATE_ADD_MODAL
});
