import React from 'react';
import {Modal} from "semantic-ui-react";
import {connect} from "react-redux";
import {closeJobOpeningCreateModal} from "../actions";
import JobOpeningForm from "./form/job-opening-form";

export class CreateJobOpeningModal extends React.Component {
  render() {
    return (
      <Modal
        open={this.props.showModal}
        onClose={this.props.closeJobOpeningCreateModal}
        centered closeIcon dimmer={'blurring'}>
        <Modal.Header>{'Añadir búsqueda'}</Modal.Header>
        <Modal.Content>
          <JobOpeningForm/>
        </Modal.Content>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  showModal: state.jobOpeningsUI.showJobOpeningCreateModal
});

const mapDispatchToProps = {
  closeJobOpeningCreateModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateJobOpeningModal);
