/**********************
 *   CANDIDATE_DETAIL
 **********************/
export const CANDIDATE_DETAIL_FETCH = 'CANDIDATE_DETAIL_FETCH';
export const CANDIDATE_DETAIL_FETCH_SUCCESS = 'CANDIDATE_DETAIL_FETCH_SUCCESS';
export const CANDIDATE_DETAIL_FETCH_FAILURE = 'CANDIDATE_DETAIL_FETCH_FAILURE';
export const CANDIDATE_DETAIL_FETCH_RESET_STATUS = 'CANDIDATE_DETAIL_FETCH_RESET_STATUS';
export const CANDIDATE_DETAIL_FETCH_SET_ID = 'CANDIDATE_DETAIL_FETCH_SET_ID';
export const CANDIDATE_DETAIL_EDIT = 'CANDIDATE_DETAIL_EDIT';
export const CANDIDATE_DETAIL_EDIT_SUCCESS = 'CANDIDATE_DETAIL_EDIT_SUCCESS';
export const CANDIDATE_DETAIL_EDIT_FAILURE = 'CANDIDATE_DETAIL_EDIT_FAILURE';

/*********
 *   UI
 *********/
export const SHOW_CANDIDATE_DETAIL_EDIT_MODAL = "SHOW_CANDIDATE_DETAIL_EDIT_MODAL";
export const CLOSE_CANDIDATE_DETAIL_EDIT_MODAL = "CLOSE_CANDIDATE_DETAIL_EDIT_MODAL";
