import React from 'react';
import {Dimmer, Loader} from "semantic-ui-react";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {compose} from "redux";
import {fetchCandidateDetails} from "./actions";
import Profile from "./components/profile";
import EditCandidateModal from "./components/edit-candidate-modal"

export class CandidateDetails extends React.Component {
  componentDidMount() {
    this.props.fetchCandidateDetails(this.props.match.params.candidateId);
  }


  render() {
    const {candidate, candidateWasFetched} = this.props;
    return (
      candidateWasFetched ?
        <>
          <Profile candidate={candidate}/>
          <EditCandidateModal candidate={candidate}/>
        </> :
        <Dimmer active inverted>
          <Loader>{'Cargando candidato'}</Loader>
        </Dimmer>
    );
  }
}

const mapStateToProps = state => ({
  candidate: state.candidateDetails.entity,
  candidateWasFetched: state.candidateDetails.fetch.done,
});

const mapDispatchToProps = {
  fetchCandidateDetails
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(CandidateDetails);
