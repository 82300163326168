import React from 'react';
import {Button} from "semantic-ui-react";

const DeleteButton = ({onClick}) => (
  <Button
    icon={'trash'} circular size={'mini'} color={'red'}
    onClick={onClick}
  />
);

export default DeleteButton;
