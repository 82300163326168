import React, {useState} from 'react';
import {Form, Grid} from "semantic-ui-react";
import {connect} from "react-redux";
import {useHistory, useLocation} from "react-router-dom";
import {loginUser, logOutUser} from "../../redux/actions/auth-actions";
import {ACCESS_TOKEN, REFRESH_TOKEN, setCookie} from "../../utils";
import agent from "../../api_client/api-client";

function LoginPage({loginUser}) {
  let history = useHistory();
  let location = useLocation();
  let [credentials, setCredentials] = useState({});


  const handleInputChange = (event) => {
    event.persist();
    setCredentials(credentials => ({...credentials, [event.target.name]: event.target.value}));
  };

  function handleSubmit(event) {
    event.preventDefault();

    agent.Auth.login(credentials)
      .then(response => {
        // Set tokens
        setCookie(ACCESS_TOKEN, response['access'], 7);
        setCookie(REFRESH_TOKEN, response['refresh'], 7);
        loginUser();

        // Redirect user
        let {from} = location.state || {from: {pathname: "/"}};
        history.replace(from.pathname);
        // from ? history.replace(from) : history.replace(candidatesRoute);
      })
  }

  return (
    <Grid centered columns={'equal'} textAlign={'center'} verticalAlign={'middle'} style={{height: '100vh'}}>
      <Grid.Row>
        <Grid.Column width={3}>
          <Form onSubmit={handleSubmit}>
            {/*<Header content={'Inicia sesión'} textAlign={'center'}/>*/}
            <Form.Input
              name={'username'}
              placeholder={'Nombre de usuario'}
              onChange={handleInputChange}
            />
            <Form.Input
              type={'password'}
              name={'password'}
              placeholder={'Contraseña'}
              onChange={handleInputChange}
            />
            <Form.Button
              fluid
              content={'Iniciar sesión'}
              color={'teal'}
              disabled={credentials.username === '' || credentials.password === ''}
            />
          </Form>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
});

const mapDispatchToProps = {
  loginUser,
  logOutUser
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
