import React from 'react';
import {Confirm} from "semantic-ui-react";
import {connect} from "react-redux";
import {closeClientCompanyDeleteModal, deleteClientCompany} from "../actions";

export class DeleteClientCompanyModal extends React.Component {
  render() {
    return (
      <Confirm
        open={this.props.showClientCompanyDeleteModal}
        content={'¿Estas seguro de eliminar?'}
        size={'mini'}
        confirmButton={'Eliminar'}
        cancelButton={'Cancelar'}
        onCancel={this.props.closeClientCompanyDeleteModal}
        onConfirm={() => this.props.deleteClientCompany(this.props.clientCompanyId)}
      />
    );
  }
}

const mapDispatchToProps = {
  deleteClientCompany,
  closeClientCompanyDeleteModal
};

const mapStateToProps = state => ({
  clientCompanyId: state.clientCompanies.delete.currentId,
  showClientCompanyDeleteModal: state.clientCompaniesUI.showClientCompanyDeleteModal
});

export default connect(mapStateToProps, mapDispatchToProps)(DeleteClientCompanyModal);
