import React from 'react';
import {Dropdown} from "semantic-ui-react";
import {connect} from "react-redux";
import {fetchTechnologies} from "./actions";
import {renderLabel} from "../builders";

class TechnologiesDropdown extends React.Component {
  componentDidMount() {
    if (!this.props.technologies || !this.props.technologies.length) {
      this.props.fetchTechnologies();
    }
  }

  render() {
    const {name, value, multiple, onChange, technologies = []} = this.props;
    return (
      <Dropdown search selection fluid
                name={name}
                value={value}
                multiple={multiple}
                onChange={onChange}
                loading={!technologies}
                options={technologies.map(tech => ({
                  key: tech.id,
                  value: tech.id,
                  text: tech.name,
                }))}
                renderLabel={renderLabel}
                placeholder={'Tecnologías'}
                noResultsMessage={'No se encontraron resultados.'}
      />
    );
  }
}

const mapStateToProps = state => ({
  technologies: state.technologies.items,
});

const mapDispatchToProps = {
  fetchTechnologies,
};

export default connect(mapStateToProps, mapDispatchToProps)(TechnologiesDropdown);
