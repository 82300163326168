import {applyMiddleware, createStore} from "redux";
import {persistReducer, persistStore} from 'redux-persist'
import {createBrowserHistory} from 'history'
import {routerMiddleware} from 'connected-react-router'
import rootReducer from './reducers'
import storage from 'redux-persist/lib/storage'
import thunkMiddleware from "redux-thunk";
import ApiClientInterceptors from "../api_client/api-client-interceptors";
import {composeWithDevTools} from "redux-devtools-extension";

const persistConfig = {
  key: 'root',
  storage: storage,
  // stateReconciler: autoMergeLevel2,
  whitelist: [
    'auth',
  ]
};
const history = createBrowserHistory();
const persistedReducer = persistReducer(persistConfig, rootReducer(history));

// Redux store
const store = createStore(
  persistedReducer,
  composeWithDevTools(
    applyMiddleware(
      routerMiddleware(history),
      thunkMiddleware
    ),
  )
);

// Middleware: Redux Persist Persister
let persistor = persistStore(store);

// Setup axios agent interceptor
ApiClientInterceptors.setupInterceptors(store, history);

// Exports
export {
  history,
  store,
  persistor,
};
