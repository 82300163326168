import {
  CANDIDATE_DETAIL_EDIT,
  CANDIDATE_DETAIL_EDIT_FAILURE, CANDIDATE_DETAIL_EDIT_SUCCESS,
  CANDIDATE_DETAIL_FETCH,
  CANDIDATE_DETAIL_FETCH_FAILURE, CANDIDATE_DETAIL_FETCH_RESET_STATUS,
  CANDIDATE_DETAIL_FETCH_SET_ID,
  CANDIDATE_DETAIL_FETCH_SUCCESS, CLOSE_CANDIDATE_DETAIL_EDIT_MODAL, SHOW_CANDIDATE_DETAIL_EDIT_MODAL
} from "./action-types";

const initialState = {
  entity: undefined,
  fetch: {
    currentId: false,
    pending: false,
    done: false,
    error: false
  },
  update: {
    pending: false,
    done: false,
    error: undefined
  }
};

export const candidateDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case CANDIDATE_DETAIL_FETCH:
      return {
        ...state,
        fetch: {
          ...initialState,
          pending: true
        }
      };
    case CANDIDATE_DETAIL_FETCH_SUCCESS:
      return {
        ...state,
        entity: action.payload.candidate,
        fetch: {
          ...initialState,
          done: true
        }
      };
    case CANDIDATE_DETAIL_FETCH_FAILURE:
      return {
        ...state,
        entity: undefined,
        fetch: {
          ...initialState,
          error: true
        }
      };
    case CANDIDATE_DETAIL_FETCH_SET_ID:
      return {
        ...state,
        entity: undefined,
        fetch: {
          ...initialState,
          currentId: action.payload.candidateId,
          error: true
        }
      };
    case CANDIDATE_DETAIL_FETCH_RESET_STATUS:
      return {
        ...initialState
      };
    case CANDIDATE_DETAIL_EDIT:
      return {
        ...state,
        update: {
          ...initialState.update,
          pending: true,
        }
      };
    case CANDIDATE_DETAIL_EDIT_SUCCESS:
      return {
        ...state,
        entity: action.payload.candidate,
        update: {
          ...initialState.update,
          done: true
        }
      };
    case CANDIDATE_DETAIL_EDIT_FAILURE:
      return {
        ...state,
        update: {
          ...initialState.update,
          error: action.payload.error
        }
      };
    default:
      return state
  }
};

const candidateDetailsUIReducerInitialState = {
  showCandidateEditModal: false
};

export const candidateDetailsUIReducer = (state = candidateDetailsUIReducerInitialState, action) => {
  switch (action.type) {
    case SHOW_CANDIDATE_DETAIL_EDIT_MODAL:
      return {
        showCandidateEditModal: true,
      };
    case CLOSE_CANDIDATE_DETAIL_EDIT_MODAL:
      return {
        showCandidateEditModal: false,
      };
    default:
      return state;
  }
};
