import {
  CANDIDATE_CREATE,
  CANDIDATE_CREATE_FAILURE,
  CANDIDATE_CREATE_RESET_STATUS,
  CANDIDATE_CREATE_SUCCESS,
  CANDIDATE_DELETE,
  CANDIDATE_DELETE_FAILURE,
  CANDIDATE_DELETE_RESET_STATUS,
  CANDIDATE_DELETE_SET_CURRENT_ID,
  CANDIDATE_DELETE_SUCCESS,
  CANDIDATE_FETCH,
  CANDIDATE_FETCH_FAILURE,
  CANDIDATE_FETCH_SUCCESS,
  CANDIDATE_UPDATE_SUCCESS,
  CLOSE_CANDIDATE_CREATE_MODAL,
  CLOSE_CANDIDATE_DELETE_MODAL,
  CLOSE_CANDIDATE_DETAIL_MODAL,
  OPEN_CANDIDATE_CREATE_MODAL,
  OPEN_CANDIDATE_DELETE_MODAL,
  OPEN_CANDIDATE_DETAIL_MODAL
} from "./action-types";

export const candidatesReducerInitialState = {
  entities: [],
  entitiesCount: 0,
  create: {
    pending: false,
    done: false,
    error: false
  },
  fetch: {
    pending: false,
    done: false,
    error: false
  },
  delete: {
    currentId: undefined,
    pending: false,
    done: false,
    error: false
  }
};

export const candidatesReducer = (state = candidatesReducerInitialState, action) => {
  switch (action.type) {
    case CANDIDATE_CREATE:
      return {
        ...state,
        create: {
          ...candidatesReducerInitialState.create,
          pending: true,
        }
      };
    case CANDIDATE_CREATE_SUCCESS:
      return {
        ...state,
        entities: [
          ...state.entities,
          action.payload.candidate
        ],
        entitiesCount: state.entitiesCount + 1,
        create: {
          ...candidatesReducerInitialState.create,
          done: true
        }
      };
    case CANDIDATE_CREATE_FAILURE:
      return {
        ...state,
        create: {
          ...candidatesReducerInitialState.create,
          error: true
        }
      };
    case CANDIDATE_CREATE_RESET_STATUS:
      return {
        ...state,
        create: {
          ...candidatesReducerInitialState.create
        }
      };
    case CANDIDATE_FETCH:
      return {
        ...state,
        fetch: {
          ...candidatesReducerInitialState.fetch,
          pending: true
        }
      };
    case CANDIDATE_FETCH_SUCCESS:
      return {
        ...state,
        entities: action.payload.candidates,
        entitiesCount: action.payload.candidatesCount,
        fetch: {
          ...candidatesReducerInitialState.fetch,
          done: true
        }
      };
    case CANDIDATE_FETCH_FAILURE:
      return {
        ...state,
        fetch: {
          ...candidatesReducerInitialState.fetch,
          error: true
        }
      };
    case CANDIDATE_DELETE:
      return {
        ...state,
        delete: {
          ...candidatesReducerInitialState.delete,
          pending: true,
          currentId: action.payload.candidateId
        }
      };
    case CANDIDATE_DELETE_SUCCESS:
      return {
        ...state,
        entities: state.entities.filter(candidate => candidate.id !== action.payload.candidateId),
        entitiesCount: state.entitiesCount - 1,
        delete: {
          ...candidatesReducerInitialState.delete,
          done: true,
        }
      };
    case CANDIDATE_DELETE_FAILURE:
      return {
        ...state,
        delete: {
          ...candidatesReducerInitialState.delete,
          error: true,
        }
      };
    case CANDIDATE_DELETE_SET_CURRENT_ID:
      return {
        ...state,
        delete: {
          ...state.delete,
          currentId: action.payload.candidateId
        }
      };
    case CANDIDATE_DELETE_RESET_STATUS:
      return {
        ...state,
        delete: {
          ...candidatesReducerInitialState.delete
        }
      };
    case CANDIDATE_UPDATE_SUCCESS:
      return {
        ...state,
        entities: [
          ...state.entities.filter(candidate => candidate.id !== action.payload.updatedCandidate.id),
          action.payload.updatedCandidate
        ]
      };
    default:
      return state;
  }
};

export const candidatesUIReducerInitialState = {
  showCandidateCreateModal: false,
  showCandidateDetailModal: false,
  showCandidateDeleteModal: false,
};

export const candidatesUIReducer = (state = candidatesUIReducerInitialState, action) => {
  switch (action.type) {
    case OPEN_CANDIDATE_CREATE_MODAL:
      return {
        ...state,
        showCandidateCreateModal: true,
      };
    case CLOSE_CANDIDATE_CREATE_MODAL:
      return {
        ...state,
        showCandidateCreateModal: false,
      };
    case OPEN_CANDIDATE_DETAIL_MODAL:
      return {
        ...state,
        showCandidateDetailModal: true,
      };
    case CLOSE_CANDIDATE_DETAIL_MODAL:
      return {
        ...state,
        showCandidateDetailModal: false,
      };
    case OPEN_CANDIDATE_DELETE_MODAL:
      return {
        ...state,
        showCandidateDeleteModal: true,
      };
    case CLOSE_CANDIDATE_DELETE_MODAL:
      return {
        ...state,
        showCandidateDeleteModal: false
      };
    default:
      return state;
  }
};
