import {
  JOB_OPENING_CANDIDATE_STATUS_FETCH,
  JOB_OPENING_CANDIDATE_STATUS_FETCH_FAILURE,
  JOB_OPENING_CANDIDATE_STATUS_FETCH_SUCCESS
} from "./action-types";
import agent from "../../api_client/api-client";

const fetchJobOpeningCandidateStatusRequest = _ => ({
  type: JOB_OPENING_CANDIDATE_STATUS_FETCH
});

const fetchJobOpeningCandidateStatusSuccess = response => ({
  type: JOB_OPENING_CANDIDATE_STATUS_FETCH_SUCCESS,
  payload: {
    candidateStatuses: response
  }
});

const fetchJobOpeningCandidateStatusFailure = error => ({
  type: JOB_OPENING_CANDIDATE_STATUS_FETCH_FAILURE,
  error: error.toString()
});

export const fetchJobOpeningCandidateStatus = () => dispatch => {
  dispatch(fetchJobOpeningCandidateStatusRequest());
  return agent.CandidateStatus.list()
    .then(response => dispatch(fetchJobOpeningCandidateStatusSuccess(response)))
    .catch(error => dispatch(fetchJobOpeningCandidateStatusFailure(error)));
};
