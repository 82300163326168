/*************
 *  Entities
 *************/
export const CANDIDATE_CREATE = 'CANDIDATE_CREATE';
export const CANDIDATE_CREATE_SUCCESS = 'CANDIDATE_CREATE_SUCCESS';
export const CANDIDATE_CREATE_FAILURE = 'CANDIDATE_CREATE_FAILURE';
export const CANDIDATE_CREATE_RESET_STATUS = 'CANDIDATE_CREATE_RESET_STATUS';

export const CANDIDATE_FETCH = 'CANDIDATE_FETCH';
export const CANDIDATE_FETCH_SUCCESS = 'CANDIDATE_FETCH_SUCCESS';
export const CANDIDATE_FETCH_FAILURE = 'CANDIDATE_FETCH_FAILURE';
export const CANDIDATE_FETCH_RESET_STATUS = 'CANDIDATE_FETCH_RESET_STATUS';

export const CANDIDATE_UPDATE = 'CANDIDATE_UPDATE';
export const CANDIDATE_UPDATE_SUCCESS = 'CANDIDATE_UPDATE_SUCCESS';
export const CANDIDATE_UPDATE_FAILURE = 'CANDIDATE_UPDATE_FAILURE';
export const CANDIDATE_UPDATE_RESET_STATUS = 'CANDIDATE_UPDATE_RESET_STATUS';

export const CANDIDATE_DELETE = 'CANDIDATE_DELETE';
export const CANDIDATE_DELETE_SUCCESS = 'CANDIDATE_DELETE_SUCCESS';
export const CANDIDATE_DELETE_FAILURE = 'CANDIDATE_DELETE_FAILURE';
export const CANDIDATE_DELETE_RESET_STATUS = 'CANDIDATE_DELETE_RESET_STATUS';
export const CANDIDATE_DELETE_SET_CURRENT_ID = 'CANDIDATE_DELETE_SET_CURRENT_ID';

/**************
 *     UI
 **************/
export const OPEN_CANDIDATE_CREATE_MODAL = 'OPEN_CANDIDATE_CREATE_MODAL';
export const CLOSE_CANDIDATE_CREATE_MODAL = 'CLOSE_CANDIDATE_CREATE_MODAL';
export const OPEN_CANDIDATE_DETAIL_MODAL = 'OPEN_CANDIDATE_DETAIL_MODAL';
export const CLOSE_CANDIDATE_DETAIL_MODAL = 'CLOSE_CANDIDATE_DETAIL_MODAL';
export const OPEN_CANDIDATE_DELETE_MODAL = 'OPEN_CANDIDATE_DELETE_MODAL';
export const CLOSE_CANDIDATE_DELETE_MODAL = 'CLOSE_CANDIDATE_DELETE_MODAL';
