import {
  JOB_OPENING_SEARCH,
  JOB_OPENING_SEARCH_FAILURE,
  JOB_OPENING_SEARCH_RESET_STATUS,
  JOB_OPENING_SEARCH_SELECT_JOB_OPENING, JOB_OPENING_SEARCH_SUCCESS,
  JOB_OPENING_SEARCH_UNSELECT_JOB_OPENING
} from "./action-types";

const initialState = {
  pending: false,
  done: false,
  error: false,
  results: [],
  selected: [],
  currentQuery: undefined,
};

export const jobOpeningSearchReducer = (state = initialState, action) => {
  switch (action.type) {
    case JOB_OPENING_SEARCH_RESET_STATUS:
      return initialState;
    case JOB_OPENING_SEARCH:
      return {
        ...state,
        ...initialState,
        pending: true,
        currentQuery: action.payload.searchQuery
      };
    case JOB_OPENING_SEARCH_SUCCESS:
      return {
        ...state,
        pending: false,
        done: true,
        results: action.payload.jobOpenings
      };
    case JOB_OPENING_SEARCH_FAILURE:
      return {
        ...initialState,
        error: action.payload.error
      };
    case JOB_OPENING_SEARCH_SELECT_JOB_OPENING:
      return {
        ...state,
        selected: [
          ...state.selected,
          ...state.results.filter(result =>
            action.payload.jobOpenings.some(jobId =>
              jobId === result.id && !state.selected.some(selected => selected.id === jobId))
          )
        ]
      };
    case JOB_OPENING_SEARCH_UNSELECT_JOB_OPENING:
      return {
        ...state,
        selected: [
          ...state.selected.filter(jobOpening => jobOpening.id !== action.payload.jobOpeningId),
        ]
      };
    default:
      return state;
  }
};
