import React from 'react';
import {Confirm} from "semantic-ui-react";
import {connect} from "react-redux";
import {closeJobOpeningDeleteModal, deleteJobOpening} from "../actions";

export class DeleteJobOpeningModal extends React.Component {
  render() {
    return (
      <Confirm
        open={this.props.showJobOpeningDeleteModal}
        content={'¿Estas seguro de eliminar?'}
        size={'mini'}
        confirmButton={'Eliminar'}
        cancelButton={'Cancelar'}
        onCancel={this.props.closeJobOpeningDeleteModal}
        onConfirm={() => this.props.deleteJobOpening(this.props.jobOpeningId)}
      />
    );
  }
}

const mapDispatchToProps = {
  deleteJobOpening,
  closeJobOpeningDeleteModal
};

const mapStateToProps = state => ({
  jobOpeningId: state.jobOpenings.delete.currentId,
  deletingJobOpening: state.jobOpenings.delete.pending,
  jobOpeningDeleted: state.jobOpenings.delete.completed,
  jobOpeningDeleteError: state.jobOpenings.delete.error,
  showJobOpeningDeleteModal: state.jobOpeningsUI.showJobOpeningDeleteModal
});

export default connect(mapStateToProps, mapDispatchToProps)(DeleteJobOpeningModal);
