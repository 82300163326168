import React from 'react'
import {Button, Grid, Header, Search, Segment} from "semantic-ui-react";

class SearchBar extends React.Component {
  render() {
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column width={6} floated={'left'} verticalAlign={'bottom'}>
            <Segment basic compact>
              <Header content={this.props.title} size={'large'}/>
            </Segment>
          </Grid.Column>
          <Grid.Column width={6} floated={'right'} verticalAlign={'middle'}>
            <Segment basic compact floated={'right'}>
              <Button icon={'add'} circular color={'green'} size={'large'} style={{marginRight: '1em'}}
                      onClick={this.props.addButtonAction}/>
              <Search
                style={{display: 'inline-block'}}
                placeholder={'Buscar..'}
                showNoResults={false}
                minCharacters={3}
                loading={false}
              />
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    )
  }
}

export default SearchBar;
