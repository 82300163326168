import React from 'react';
import {Container, Dimmer, Loader, Pagination, Segment} from "semantic-ui-react";
import CandidatesTable from "./components/candidates-table";
import SearchBar from "../../components/search-bar";
import {connect} from "react-redux";
import {fetchCandidates, openCandidateCreateModal} from "./actions";

class CandidatesPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
      totalPages: 5,
      candidatesPerPage: 10
    };
  }

  componentDidMount() {
    this.fetchCandidatesAndUpdatePagination();
  };

  fetchCandidatesAndUpdatePagination = (offset = 0, activePage = 1) => {
    this.props.fetchCandidates(offset)
      .then(_ => {
        this.setState({
          activePage: activePage,
          totalPages: Math.ceil(this.props.candidatesCount / this.state.candidatesPerPage)
        });
      });
  };

  handlePaginationChange = (event, {activePage}) => {
    const offset = this.state.candidatesPerPage * (activePage - 1);
    this.fetchCandidatesAndUpdatePagination(offset, activePage);
  };

  render() {
    return (
      <Container fluid style={{marginTop: '1em'}}>
        {
          this.props.isFetchingCandidates ?
            <Dimmer active inverted>
              <Loader>{'Cargando candidatos'}</Loader>
            </Dimmer> :
            <React.Fragment>
              <SearchBar title={'Candidatos'} addButtonAction={this.props.openCandidateCreateModal}/>
              <div style={{margin: '0 1em 1em 1em', overflowX: 'scroll'}}>
                <CandidatesTable candidates={this.props.candidates}/>
              </div>
              <Segment basic floated={'right'}>
                <Pagination
                  activePage={this.state.activePage}
                  totalPages={this.state.totalPages}
                  onPageChange={this.handlePaginationChange}
                  boundaryRange={0}
                  siblingRange={2}
                  style={{margin: '0 3em 3em 3em'}}
                />
              </Segment>
            </React.Fragment>
        }
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  candidates: state.candidates.entities,
  candidatesCount: state.candidates.entitiesCount,
  isFetchingCandidates: state.candidates.fetch.pending,
});

const mapDispatchToProps = {
  fetchCandidates,
  openCandidateCreateModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(CandidatesPage);
