import {Dropdown, Grid} from "semantic-ui-react";
import React from "react";
import {renderLabel} from "../../../../../components/builders";

export const TechAndLanguagesRow = ({technologies, languages, onChange}) => (
  <Grid.Row columns={2}>
    <Grid.Column>
      <div className={'label'}>
        {'Tecnologías'}
      </div>
      <Dropdown
        multiple search selection fluid
        name={'technologiesRequired'}
        options={
          technologies
            ? technologies.map(tech => ({
              key: tech.id,
              value: tech.id,
              text: tech.name,
            }))
            : []
        }
        loading={!technologies}
        renderLabel={renderLabel}
        onChange={onChange}
        placeholder={'Tecnologías'}
        noResultsMessage={'No se encontraron resultados.'}
      />
    </Grid.Column>
    <Grid.Column>
      <div className={'label'}>
        {'Idiomas'}
      </div>
      <Dropdown
        basic search multiple selection clearable scrolling lazyLoad fluid
        name={'languagesRequired'}
        options={
          languages
            ? languages.map(lang => ({
              key: lang.id,
              value: lang.id,
              text: lang.name + ' - ' + lang.level,
            }))
            : []
        }
        renderLabel={renderLabel}
        onChange={onChange}
        placeholder={'Idiomas'}
        noResultsMessage={'No se encontraron resultados.'}
      />
    </Grid.Column>
  </Grid.Row>
);
