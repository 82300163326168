import React from "react";
import {Icon, Message, Modal} from "semantic-ui-react";
import {connect} from "react-redux";
import {CandidateForm} from "../../../components/forms/candidate-form/candidate-form";
import {handleFormChange} from "../../../components/forms/candidate-form/candidate-form-helper";
import {closeEditCandidateModal, updateCandidateDetails} from "../actions";
import {buildCandidateForAPI} from "../../../components/builders";

class EditCandidateModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      candidate: buildCandidateForAPI(props.candidate)
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = handleFormChange.bind(this);
  }

  handleSubmit(e) {
    e.preventDefault();
    this.props.updateCandidateDetails(this.props.candidate.id, this.state.candidate);
  }

  render() {
    const {showModal, closeModal, isUpdatingCandidate, isUpdated, updateError} = this.props;
    return (
      <Modal open={showModal} onClose={closeModal}>
        <Modal.Header>{'Editar candidato'}</Modal.Header>
        <Modal.Content>
          <CandidateForm
            isEdit={true}
            onChange={this.handleChange}
            onSubmit={this.handleSubmit}
            candidate={this.state.candidate}
          />

          {/* Form status messages */}
          <Message icon hidden={!isUpdatingCandidate} color={'orange'}>
            <Icon name='circle notched' loading/>
            <Message.Content>
              <Message.Header>{'Actualizando candidato'}</Message.Header>
              {'Solo un momento..'}
            </Message.Content>
          </Message>
          <Message icon hidden={!isUpdated} positive>
            <Message.Content>
              <Message.Header>{'Candidato actualizado!'}</Message.Header>
              {'Ya podés regresar a la tabla de candidatos.'}
            </Message.Content>
          </Message>
          <Message icon hidden={!updateError} color={'red'}>
            <Message.Content>
              <Message.Header>{'Ocurrió un error al actualizar el candidato.'}</Message.Header>
              {'Asegurate de que no exista un candidato registrado con ese email.'}
            </Message.Content>
          </Message>
        </Modal.Content>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  showModal: state.candidateDetailsUI.showCandidateEditModal,
  isUpdatingCandidate: state.candidateDetails.update.pending,
  isUpdated: state.candidateDetails.update.done,
  updateError: state.candidateDetails.update.error
});

const mapDispatchToProps = {
  closeModal: closeEditCandidateModal,
  updateCandidateDetails
};

export default connect(mapStateToProps, mapDispatchToProps)(EditCandidateModal);
