import _ from 'lodash';
import React from 'react';
import {Form, Grid, Icon, Message} from "semantic-ui-react";
import {addJobOpening, fetchJobOpeningsPriorities, fetchJobOpeningsStatus} from "../../actions";
import {fetchTechnologies} from "../../../../components/technologies-dropdown/actions";
import {connect} from "react-redux";
import {fetchLanguages} from "../../../../components/languages/actions";
import {fetchClientCompanies} from "../../../client-companies/actions";
import {JobOpeningTitleRow} from "./rows/title-row";
import {ClientsRow} from "./rows/clients-row";
import {TechAndLanguagesRow} from "./rows/techs-and-lang-row";
import {DetailsRow} from "./rows/details-row";
import {DescriptionRow} from "./rows/description-row";
import './rows/rows.css';
import {CandidatesRow} from "./rows/candidates-row";

class JobOpeningForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount(props) {
    this.props.fetchClientCompanies();
    this.props.fetchTechnologies();
    this.props.fetchLanguages();
    this.props.fetchJobOpeningsStatus();
    this.props.fetchJobOpeningsPriorities();
  }

  handleChange(event, data) {
    if ([
      'technologiesRequired',
      'languagesRequired',
      'candidates'
    ].includes(data.name)) {
      this.setState({
        [event.target.name]: event.target.value
      })
    } else {
      this.setState({
        [data.name]: data.value
      })
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    const jobOpening = {
      name: _.toString(this.state.name),
      description: _.toString(this.state.description),
      technologiesRequired: this.state.technologiesRequired,
      languagesRequired: _.toArray(this.state.languagesRequired),
      clientCompany: _.toSafeInteger(this.state.clientCompany),
      finalCompany: _.toSafeInteger(this.state.finalCompany),
      priority: _.toSafeInteger(this.state.priority),
      status: _.toSafeInteger(this.state.status),
      minSalary: _.toSafeInteger(this.state.minSalary),
      maxSalary: _.toSafeInteger(this.state.maxSalary),
      candidates: _.toArray(this.state.candidates),
    };
    this.props.addJobOpening(jobOpening);
  }

  render() {
    return (
      <Form success={false} onSubmit={this.handleSubmit}>
        <Grid centered>
          <JobOpeningTitleRow onChange={this.handleChange}/>
          <ClientsRow
            companies={this.props.companies}
            onChange={this.handleChange}/>
          <TechAndLanguagesRow
            technologies={this.props.technologies}
            languages={this.props.languages}
            onChange={this.handleChange}/>
          <DetailsRow
            statuses={this.props.statuses}
            priorities={this.props.priorities}
            onChange={this.handleChange}/>
          <DescriptionRow
            onChange={this.handleChange}/>
          <CandidatesRow
            onChange={this.handleChange}
          />

          <Form.Button positive>{'Añadir búsqueda'}</Form.Button>
        </Grid>

        <Message icon hidden={!this.props.creatingJobOpening} color={'orange'}>
          <Icon name='circle notched' loading/>
          <Message.Content>
            <Message.Header>{'Añadiendo búsqueda..'}</Message.Header>
          </Message.Content>
        </Message>
        <Message icon hidden={!this.props.jobOpeningCreated} positive>
          <Message.Content>
            <Message.Header>{'Búsqueda creada!'}</Message.Header>
          </Message.Content>
        </Message>
        <Message icon hidden={!this.props.jobOpeningCreationError} color={'red'}>
          <Message.Content>
            <Message.Header>{'Ocurrió un error al intentar añadir la búsqueda.'}</Message.Header>
          </Message.Content>
        </Message>
      </Form>
    )
  }
}

/*******************
 *   Form Fields
 *******************/
const mapStateToProps = state => ({
  companies: state.clientCompanies.entities,
  technologies: state.technologies.items,
  languages: state.languages.items,
  statuses: state.jobOpeningsStatuses.items,
  priorities: state.jobOpeningsPriorities.items,

  creatingJobOpening: state.jobOpenings.creatingJobOpening,
  jobOpeningCreated: state.jobOpenings.jobOpeningCreated,
  jobOpeningCreationError: state.jobOpenings.jobOpeningCreationError,
});

const mapDispatchToProps = {
  addJobOpening,
  fetchClientCompanies,
  fetchLanguages,
  fetchTechnologies,
  fetchJobOpeningsPriorities,
  fetchJobOpeningsStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(JobOpeningForm);
