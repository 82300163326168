import {
  CLOSE_JOB_OPENING_CANDIDATE_ADD_MODAL,
  CLOSE_JOB_OPENING_CANDIDATE_DELETE_CONFIRMATION,
  JOB_OPENING_CANDIDATE_ADD,
  JOB_OPENING_CANDIDATE_ADD_FAILURE,
  JOB_OPENING_CANDIDATE_ADD_SUCCESS,
  JOB_OPENING_CANDIDATE_DELETE,
  JOB_OPENING_CANDIDATE_DELETE_FAILURE,
  JOB_OPENING_CANDIDATE_DELETE_SET_ID,
  JOB_OPENING_CANDIDATE_DELETE_SUCCESS,
  JOB_OPENING_DETAIL_FETCH,
  JOB_OPENING_DETAIL_FETCH_FAILURE,
  JOB_OPENING_DETAIL_FETCH_RESET_STATUS,
  JOB_OPENING_DETAIL_FETCH_SET_ID,
  JOB_OPENING_DETAIL_FETCH_SUCCESS,
  OPEN_JOB_OPENING_CANDIDATE_ADD_MODAL,
  OPEN_JOB_OPENING_CANDIDATE_DELETE_CONFIRMATION
} from "./action-types";

const initialState = {
  entity: undefined,
  fetch: {
    currentId: false,
    pending: false,
    done: false,
    error: false
  },
  remove: {
    currentId: false,
    pending: false,
    done: false,
    error: false
  }
};

export const jobOpeningDetailReducer = (state = initialState, action) => {
  switch (action.type) {
    case JOB_OPENING_DETAIL_FETCH:
      return {
        ...state,
        fetch: {
          ...initialState,
          pending: true
        }
      };
    case JOB_OPENING_DETAIL_FETCH_SUCCESS:
      return {
        ...state,
        entity: action.payload.jobOpening,
        fetch: {
          ...initialState,
          done: true
        }
      };
    case JOB_OPENING_DETAIL_FETCH_FAILURE:
      return {
        ...state,
        entity: undefined,
        fetch: {
          ...initialState,
          error: true
        }
      };
    case JOB_OPENING_DETAIL_FETCH_SET_ID:
      return {
        ...state,
        entity: undefined,
        fetch: {
          ...initialState,
          currentId: action.payload.jobOpeningId,
          error: true
        }
      };
    case JOB_OPENING_DETAIL_FETCH_RESET_STATUS:
      return {
        ...initialState
      };
    case JOB_OPENING_CANDIDATE_ADD:
      return state;
    case JOB_OPENING_CANDIDATE_ADD_SUCCESS:
      const filtered = state.entity.appliedCandidates.filter(prev =>
        action.payload.candidates.some(current =>
          current.candidateId !== prev.candidateId &&
          current.jobOpeningId !== prev.jobOpeningId));
      return {
        ...state,
        entity: {
          ...state.entity,
          appliedCandidates: [
            ...filtered,
            ...action.payload.candidates
          ]
        }
      };
    case JOB_OPENING_CANDIDATE_ADD_FAILURE:
      return {
        ...state,
      };
    case JOB_OPENING_CANDIDATE_DELETE_SET_ID:
      return {
        ...state,
        remove: {
          ...initialState.remove,
          currentId: action.payload.relationId
        }
      };
    case JOB_OPENING_CANDIDATE_DELETE:
      return {
        ...state,
        remove: {
          ...initialState.remove,
          pending: true
        }
      };
    case JOB_OPENING_CANDIDATE_DELETE_SUCCESS:
      return {
        ...state,
        entity: {
          ...state.entity,
          appliedCandidates: state.entity.appliedCandidates.filter(rel => rel.id !== action.payload.deletedCandidateId),
        },
        remove: {
          ...initialState.remove,
          done: true
        }
      };
    case JOB_OPENING_CANDIDATE_DELETE_FAILURE:
      return state;
    default:
      return state
  }
};

const jobOpeningDetailUIReducerInitialState = {
  showDeleteCandidateConfirmation: false,
  showAddCandidateModal: false
};

export const jobOpeningDetailUIReducer = (state = jobOpeningDetailUIReducerInitialState, action) => {
  switch (action.type) {
    case OPEN_JOB_OPENING_CANDIDATE_DELETE_CONFIRMATION:
      return {
        ...state,
        showDeleteCandidateConfirmation: true
      };
    case CLOSE_JOB_OPENING_CANDIDATE_DELETE_CONFIRMATION:
      return {
        ...state,
        showDeleteCandidateConfirmation: false
      };
    case OPEN_JOB_OPENING_CANDIDATE_ADD_MODAL:
      return {
        ...state,
        showAddCandidateModal: true
      };
    case CLOSE_JOB_OPENING_CANDIDATE_ADD_MODAL:
      return {
        ...state,
        showAddCandidateModal: false
      };
    default:
      return state;
  }
};
