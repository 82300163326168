import React from 'react';
import {Dropdown, DropdownItemProps} from "semantic-ui-react";
import {renderLabel} from "../builders";
import {connect} from "react-redux";
import {searchForJobOpening} from "./actions";

class JobOpeningsSearch extends React.Component {

  render() {
    const {onChange, searchForJobOpening, searchResults, isSearching} = this.props;
    return (
      <Dropdown
        multiple selection fluid
        name={'jobOpenings'}
        placeholder={'Búsquedas'}
        onChange={onChange}
        onSearchChange={searchForJobOpening}
        options={searchResults.map(jobOpening => ({
          key: jobOpening.id,
          value: jobOpening.id,
          text: jobOpening.name + ' - ' + jobOpening.finalCompany,
        }))}
        // Bypass the default search since it won't show the searchResults if they are not part of the search input
        search={(options: DropdownItemProps[], value: string) => options}
        loading={isSearching}
        renderLabel={renderLabel}
        noResultsMessage={'No se encontraron resultados.'}
      />
    )
  }
}

const mapStateToProps = state => ({
  isSearching: state.jobOpeningSearch.pending,
  searchResults: state.jobOpeningSearch.results,
});

const mapDispatchToProps = ({
  searchForJobOpening
});

export default connect(mapStateToProps, mapDispatchToProps)(JobOpeningsSearch);
