import React from 'react'
import {Link} from "react-router-dom";
import {Container, Dropdown, Menu,} from 'semantic-ui-react'
import {candidatesRoute, clientsRoute, homeRoute, jobOpeningsRoute, taxesCalculatorRoute} from "./routes";
import {connect} from "react-redux";
import {logOutUser} from "../redux/actions/auth-actions";
import {ACCESS_TOKEN, eraseCookie, REFRESH_TOKEN} from "../utils";

export class NavBar extends React.Component {
  handleLogOut = () => {
    eraseCookie(ACCESS_TOKEN);
    eraseCookie(REFRESH_TOKEN);
    this.props.logOutUser();
  };

  render() {
    return (
      <Menu attached={'top'} inverted borderless size={'large'}
            defaultActiveIndex={0}
            style={{backgroundColor: '#D9006E'}}>
        <Container>
          <Menu.Item header as={Link} to={homeRoute} style={{color: 'white', fontWeight: 700}}>
            {'waravix'}
          </Menu.Item>
          <Menu.Item as={Link} to={candidatesRoute}>
            {'Candidatos'}
          </Menu.Item>
          <Menu.Item as={Link} to={clientsRoute}>
            {'Clientes'}
          </Menu.Item>
          <Menu.Item as={Link} to={jobOpeningsRoute}>
            {'Búsquedas'}
          </Menu.Item>
          <Menu.Item as={Link} to={taxesCalculatorRoute}>
            {'Calculadora de ganancias'}
          </Menu.Item>
          <Menu.Item position={'right'} fitted={'vertically'}>
            <Dropdown basic compact item text='Marcos Rios'>
              <Dropdown.Menu>
                <Dropdown.Item icon='log out' text='Cerrar sesión' onClick={this.handleLogOut}/>
              </Dropdown.Menu>
            </Dropdown>
          </Menu.Item>
        </Container>
      </Menu>
    );
  }
}

const mapDispatchToProps = {
  logOutUser
};

export default connect(null, mapDispatchToProps)(NavBar);
