import {
  ASSOCIATE_CANDIDATE_TO_JOB_OPENINGS,
  ASSOCIATE_CANDIDATE_TO_JOB_OPENINGS_FAILURE,
  ASSOCIATE_CANDIDATE_TO_JOB_OPENINGS_SUCCESS,
  CLOSE_APPLY_CANDIDATE_TO_JOBS_MODAL,
  SHOW_APPLY_CANDIDATE_TO_JOBS_MODAL
} from "./action-types";

const initialState = {
  showModal: false,
  currentId: undefined,
  pending: false,
  done: false,
  error: undefined,
  jobOpenings: []
};

export const candidateToJobsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_APPLY_CANDIDATE_TO_JOBS_MODAL:
      return {
        ...state,
        showModal: true,
        currentId: action.payload.candidateId
      };
    case CLOSE_APPLY_CANDIDATE_TO_JOBS_MODAL:
      return {
        ...state,
        showModal: false
      };
    case ASSOCIATE_CANDIDATE_TO_JOB_OPENINGS:
      return {
        ...state,
        pending: true
      };
    case ASSOCIATE_CANDIDATE_TO_JOB_OPENINGS_SUCCESS:
      return {
        ...state,
        pending: false,
        done: true,
        jobOpenings: action.payload.jobOpenings
      };
    case ASSOCIATE_CANDIDATE_TO_JOB_OPENINGS_FAILURE:
      return {
        ...state,
        pending: false,
        done: false,
        error: action.payload.error
      };
    default:
      return state;
  }
};
