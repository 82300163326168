// Job Opening UI
export const JOB_OPENING_OPEN_CREATE_MODAL = 'JOB_OPENING_OPEN_CREATE_MODAL';
export const JOB_OPENING_CLOSE_CREATE_MODAL = 'JOB_OPENING_CLOSE_CREATE_MODAL';
export const JOB_OPENING_SHOW_DELETE_MODAL = 'JOB_OPENING_SHOW_DELETE_MODAL';
export const JOB_OPENING_CLOSE_DELETE_MODAL = 'JOB_OPENING_CLOSE_DELETE_MODAL';

// Job Opening Entities
export const JOB_OPENING_CREATE = 'JOB_OPENING_CREATE';
export const JOB_OPENING_CREATE_DONE = 'JOB_OPENING_CREATE_DONE';
export const JOB_OPENING_CREATE_RESET_STATE = 'JOB_OPENING_CREATE_RESET_STATE';
export const JOB_OPENING_CREATE_ERROR = 'JOB_OPENING_CREATE_ERROR';

export const JOB_OPENING_REQUEST = 'JOB_OPENING_REQUEST';
export const JOB_OPENING_REQUEST_DONE = 'JOB_OPENING_REQUEST_DONE';
export const JOB_OPENING_REQUEST_RESET_STATE = 'JOB_OPENING_REQUEST_RESET_STATE';
export const JOB_OPENING_REQUEST_ERROR = 'JOB_OPENING_REQUEST_ERROR';

export const JOB_OPENING_UPDATE = 'JOB_OPENING_UPDATE';
export const JOB_OPENING_UPDATE_DONE = 'JOB_OPENING_UPDATE_DONE';
export const JOB_OPENING_UPDATE_RESET_STATE = 'JOB_OPENING_UPDATE_RESET_STATE';
export const JOB_OPENING_UPDATE_ERROR = 'JOB_OPENING_UPDATE_ERROR';

export const JOB_OPENING_DELETE = 'JOB_OPENING_DELETE';
export const JOB_OPENING_DELETE_SUCCESS = 'JOB_OPENING_DELETE_SUCCESS';
export const JOB_OPENING_DELETE_FAILURE = 'JOB_OPENING_DELETE_FAILURE';
export const JOB_OPENING_DELETE_SET_ID = 'JOB_OPENING_DELETE_SET_ID';

export const JOB_OPENING_STATUSES_REQUEST = 'JOB_OPENING_STATUSES_REQUEST';
export const JOB_OPENING_STATUSES_REQUEST_DONE = 'JOB_OPENING_STATUSES_REQUEST_DONE';
export const JOB_OPENING_STATUSES_REQUEST_RESET_STATE = 'JOB_OPENING_STATUSES_REQUEST_RESET_STATE';
export const JOB_OPENING_STATUSES_REQUEST_ERROR = 'JOB_OPENING_STATUSES_REQUEST_ERROR';

export const JOB_OPENING_PRIORITIES_REQUEST = 'JOB_OPENING_PRIORITIES_REQUEST';
export const JOB_OPENING_PRIORITIES_REQUEST_DONE = 'JOB_OPENING_PRIORITIES_REQUEST_DONE';
export const JOB_OPENING_PRIORITIES_REQUEST_RESET_STATE = 'JOB_OPENING_PRIORITIES_REQUEST_RESET_STATE';
export const JOB_OPENING_PRIORITIES_REQUEST_ERROR = 'JOB_OPENING_PRIORITIES_REQUEST_ERROR';

