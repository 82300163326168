import React from 'react';
import {Button, Grid} from "semantic-ui-react";
import {connect} from "react-redux";
import {showApplyCandidateToJobsModal} from "../../../components/apply-candidate-to-jobs-modal/actions";
import {openEditCandidateModal} from "../actions";

class ProfileActionButtons extends React.Component {
  render() {
    const {openEditCandidateModal} = this.props;
    return (
      <Grid>
        <Grid.Row style={{paddingBottom: 0}}>
          <Grid.Column>
            <Button
              content={'Editar'} fluid icon={'edit'}
              onClick={openEditCandidateModal}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row style={{paddingBottom: 0}}>
          <Grid.Column>
            <Button
              content={'Subir CV'} fluid icon={'upload'}
              onClick={() => alert('Subir CV clicked')}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row style={{paddingBottom: 0}}>
          <Grid.Column>
            <Button
              compact fluid
              content={'Informe de entrevista'} icon={'upload'}
              onClick={() => alert('Subir Informe de entrevista clicked')}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row style={{paddingBottom: 0}}>
          <Grid.Column>
            <Button
              positive fluid
              content={'Aplicar a búsquedas'}
              onClick={() => this.props.showApplyCandidateToJobsModal()}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    )
  }

}

const mapDispatchToProps = {
  openEditCandidateModal,
  showApplyCandidateToJobsModal,
};

export default connect(null, mapDispatchToProps)(ProfileActionButtons);
