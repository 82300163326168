import React from 'react';
import {Container, Pagination, Segment} from "semantic-ui-react";
import ClientCompaniesTable from "./components/client-companies-table";
import SearchBar from "../../components/search-bar";
import {fetchClientCompaniesPaginated, openClientCompanyCreateModal} from "./actions";
import {connect} from "react-redux";

export class ClientCompaniesPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
      totalPages: 5,
      clientCompaniesPerPage: 10
    };
  }

  componentDidMount() {
    this.fetchClientCompaniesAndUpdatePagination();
  };

  fetchClientCompaniesAndUpdatePagination = (offset = 0, activePage = 1) => {
    this.props.fetchClientCompaniesPaginated(offset)
      .then(_ => {
        this.setState({
          activePage: activePage,
          totalPages: Math.ceil(this.props.clientCompaniesCount / this.state.clientCompaniesPerPage)
        });
      });
  };

  handlePaginationChange = (event, {activePage}) => {
    const offset = this.state.clientCompaniesPerPage * (activePage - 1);
    this.fetchClientCompaniesAndUpdatePagination(offset, activePage);
  };

  render() {
    return (
      <Container style={{marginTop: '1em'}}>
        <SearchBar title={'Clientes'} addButtonAction={this.props.openClientCompanyCreateModal}/>
        <ClientCompaniesTable clientCompanies={this.props.clientCompanies}/>
        <Segment basic floated={'right'}>
          <Pagination
            activePage={this.state.activePage}
            totalPages={this.state.totalPages}
            onPageChange={this.handlePaginationChange}
            boundaryRange={0}
            siblingRange={2}
            style={{margin: '0 3em 3em 3em'}}
          />
        </Segment>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  clientCompanies: state.clientCompanies.paginatedEntities,
  clientCompaniesCount: state.clientCompanies.paginatedEntitiesCount,
});

const mapDispatchToProps = {
  openClientCompanyCreateModal,
  fetchClientCompaniesPaginated,
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientCompaniesPage);
