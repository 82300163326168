import {Form, Grid} from "semantic-ui-react";
import React from "react";

export const DescriptionRow = ({onChange}) => (
  <Grid.Row columns={1} style={{paddingTop: 'unset', paddingBottom: 'unset'}}>
    <Grid.Column>
      <Form.TextArea
        name={'description'}
        onChange={onChange}
        label={'Descripción'}
        placeholder={'Descripción'}
      />
    </Grid.Column>
  </Grid.Row>
);
